import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Menu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
export default function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    // Clear the authorization cookie by setting its value to an empty string and its expiration date to a past date
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    navigate("/");
  };

  return (
    <div className={menuOpen ? "menu-wrapper open" : "menu-wrapper"}>
      <div className="menu">
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
          <div className="menu-bar"></div>
        </div>

        <Link to="/home">Home</Link>
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/settings">Settings</Link>

        <div className="logout-wrapper" onClick={handleLogout}>
          <FontAwesomeIcon icon={faRightFromBracket} />
          <span style={{ display: "block" }}> </span>Logout
        </div>
      </div>
    </div>
  );
}
