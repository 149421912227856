import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { extractTokenFromCookie } from "../../utils/extractToken";

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const token = extractTokenFromCookie();
  let isAuthenticated = false;
  if (token && token !== "undefined") {
    isAuthenticated = true;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

export default PrivateRoute;
