import React from "react";
import "./Landing.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebookMessenger,
  faWhatsapp,
  faInstagram,
  faFacebook,
  faXTwitter,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

// Import the necessary styles
import "@fortawesome/fontawesome-svg-core/styles.css";
import LandingCTA from "../../components/LandingCTA/LandingCTA";
import { useNavigate } from "react-router-dom";

export default function Landing() {
  const navigate = useNavigate();
  return (
    <div id="landing-container">
      <div id="landing-left">
        <div className="part">
          <img src="/images/1-landing.png" alt="background" />
          <div className="image-overlay"></div>
        </div>

        <div className="part">
          <img src="/images/2-landing.png" alt="background" />
          <div className="image-overlay">
            <div id="text-content-1">
              <p className="main-text">
                It has never been so easy to invite people to a TeamsLunch!
              </p>
              <p className="sub-text">
                Just send them yourTeamsLunch meeting link, done!
              </p>
            </div>
          </div>
        </div>

        <div className="part">
          <img src="/images/3-landing.png" alt="background" />
          <div className="image-overlay ">
            <div id="text-content-2">
              <p className="main-text">
                Get your calendar filled with great TeamsLunch meetings!
              </p>
              <p className="sub-text">
                Just send them yourTeamsLunch meeting link, done!
              </p>
            </div>
          </div>
        </div>

        <div className="part">
          <img src="/images/4-landing.png" alt="background" />
          <div className="image-overlay">
            <div id="text-content-3">
              <p className="main-text">
                Enjoy online meetings with TeamsLunch!
              </p>
              <p className="sub-text">
                The next level of quality online meetings.
              </p>
            </div>
          </div>
        </div>

        <div className="part">
          <img src="/images/5-landing.jpg" alt="background" />
          <div className="image-overlay">
            <div id="text-content-4">
              <p className="main-text">
                Enjoy TeamsLunch in all digital media!
              </p>
              <p className="sub-text">
                Set it up in a minute and start inviting people to TeamsLunch
                meetings.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="landing-right">
        <div id="part-1">
          <div className="content">
            <img
              className="logo"
              src="/images/teamslunch-logo.png"
              alt="teamslunch logo"
            />
            <LandingCTA mt={false}>
              <h2>
                Create your TeamsLunch online calendar. It´s free forever!
              </h2>
              <p>
                Set it up in a minute and start inviting people to TeamsLunch
                meetings.
              </p>
            </LandingCTA>
            <p className="cta-sign-in">
              Already have an account?{" "}
              <span
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Sign in
              </span>
            </p>
          </div>
          <hr />
        </div>
        <div id="part-2">
          <div className="content">
            <h2>What is TeamsLunch?</h2>
            <p className="subhead">
              TeamsLunch is designed for enjoying high-quality online meetings
              with friends, family, colleagues, clients or prospects.
            </p>
            <ol>
              <li>
                Set up your TeamsLunch calendar
                <p>
                  A compact calendar home page with a presentation of yourself
                  or your company, with video and audio.
                </p>
              </li>
              <li>
                Share your calendar link
                <p>
                  Share your link with friends and family, co-workers, clients
                  or prospects.
                </p>
              </li>
              <li>
                Let the lunch be delivered with Uber Eats
                <p>
                  Either you pay for the lunch, or they do. But it´s nicer if
                  you do ;)
                </p>
              </li>
              <li>
                Enjoy the online TeamsLunch meeting
                <p>Have an online meeting while enjoying a TeamsLunch</p>
              </li>
            </ol>
            <LandingCTA mt={true}>
              <h2>Claim your TeamsLunch!</h2>
              <p>
                Set it up in a minute and start inviting people to TeamsLunch
                meetings.
              </p>
            </LandingCTA>
          </div>
          <hr />
        </div>
        <div id="part-3">
          <div className="content">
            <h2>Why TeamsLunch?</h2>
            <p className="subhead">Simple - We love lunch meetings.</p>
            <ol>
              <li>
                Reinvent lunch meetings in a world where online meetings have
                become standard.
              </li>
              <li>Enjoy quality meetings with less stressed recepients.</li>
              <li>
                Extending lunch invitations has long been a courteous tradition,
                enriching business interactions for centuries.
              </li>
            </ol>
            <LandingCTA mt={true}>
              <h2>Claim your TeamsLunch!</h2>
              <p>
                Set it up in a minute and start inviting people to TeamsLunch
                meetings.
              </p>
            </LandingCTA>
          </div>
          <hr />
        </div>
        <div id="part-4">
          <div className="content">
            <h2>Great meetings, more sales.</h2>
            <p className="subhead">True, great meetings lead to more sales.</p>
            <ol>
              <li>
                TeamsLunch was originally invented for sales people to get more
                quality meetings with potential clients.
              </li>
              <li>
                But many companies found that TeamsLunch is actually a very
                pleasant way to run corporate meetings and simplify ordering of
                lunch and keeping track of costs.
              </li>
              <li>
                And friends love to invite friends to a TeamsLunch as well as
                parents also love to invite their hungry kids to a TeamsLunch!
              </li>
            </ol>
            <LandingCTA mt={true}>
              <h2>Claim your TeamsLunch!</h2>
              <p>
                Set it up in a minute and start inviting people to TeamsLunch
                meetings.
              </p>
            </LandingCTA>
          </div>
          <hr />
        </div>
        <div id="part-5">
          <div className="content">
            <img
              className="logo"
              src="/images/teamslunch-logo.png"
              alt="teamslunch logo"
              id="landing-last-logo"
            />
            <h2>Great meetings, more sales.</h2>
            <p className="subhead">True, great meetings lead to more sales.</p>
            <div className="ps">
              <p>
                TeamsLunch was born out of the founder´s three decades of
                experience in sales, where he discovered that the most fruitful
                business relationships often began over a shared lunch with a
                prospective client or partner.
              </p>
              <p>
                In an era dominated by virtual meetings, TeamsLunch aims to
                revolutionize the concept of connecting by making it easy for
                individuals to enjoy a meal together and engage in meaningful
                conversation, despite the digital divide.
              </p>
              <p>
                This initiative is all about bringing back the personal touch to
                professional interactions, simplifying the process of sharing a
                meal, no matter where you are.
              </p>
            </div>
            <div className="social-media">
              <a
                href="https://www.linkedin.com/company/teamslunch/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
              </a>
              <a
                href="https://www.tiktok.com/@teamslunch"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="social-icon" icon={faTiktok} />
              </a>
              <a
                href="https://m.me/61556636431689"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="social-icon"
                  icon={faFacebookMessenger}
                />
              </a>
              <FontAwesomeIcon className="social-icon" icon={faWhatsapp} />
              <a
                href="https://www.instagram.com/teamslunch/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="social-icon" icon={faInstagram} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61556636431689"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="social-icon" icon={faFacebook} />
              </a>
              <a
                href="https://x.com/TeamsLunch"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="social-icon" icon={faXTwitter} />
              </a>
            </div>
            <p id="rights">&copy; All rights reserved by TeamsLunch</p>
          </div>
        </div>
      </div>
    </div>
  );
}
