import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { getAvailableTimeslots } from "../services/reservation.service";
import { setAlert } from "./AlertSlice";
import { convertUTCToLocalTime } from "../utils/timezones";

export interface TimeSlotData {
  period: string;
  active: boolean;
  reserved: boolean;
}

export interface Days {
  [day: string]: {
    slots: number;
    active: boolean;
    timeSlots: TimeSlotData[];
  };
}
interface ReservationState {
  loading: boolean;
  stage: 1 | 2;
  reserved: boolean;
  selectedDay: string | null;
  selectedSlot: { day: string; period: string } | null;
  days: Days;
}

const initialState: ReservationState = {
  loading: false,
  stage: 1,
  reserved: false,
  selectedDay: null,
  selectedSlot: null,
  days: {
    MON: {
      slots: 0,
      active: true,
      timeSlots: [],
    },
    TUE: {
      slots: 0,
      active: true,
      timeSlots: [],
    },
    WED: {
      slots: 0,
      active: true,
      timeSlots: [],
    },
    THU: {
      slots: 0,
      active: true,
      timeSlots: [],
    },
    FRI: {
      slots: 0,
      active: true,
      timeSlots: [],
    },
  },
};

export const getAvailableSlots = createAsyncThunk(
  "reservation/getAvailabilities",
  async (salesmanId: number, thunkAPI) => {
    thunkAPI.dispatch(setReservationLoading(true));

    const response = await getAvailableTimeslots(salesmanId);

    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }

      thunkAPI.dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      const currentState: RootState = thunkAPI.getState() as RootState;
      const days = JSON.parse(JSON.stringify(currentState.reservation.days));

      // clear the previously loaded appointments
      for (let day in days) {
        days[day].slots = 0;
        days[day].timeSlots = [];
      }
      response.forEach(
        (availability: {
          id: number;
          salesmanId: number;
          dayOfWeek: string;
          startTime: string;
          endTime: string;
          reserved: boolean;
        }) => {
          const periodStart = convertUTCToLocalTime(
            availability.startTime,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
          const periodEnd = convertUTCToLocalTime(
            availability.endTime,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );

          const period = `${periodStart} - ${periodEnd}`;

          days[availability.dayOfWeek].slots++;
          days[availability.dayOfWeek].timeSlots.push({
            period: period,
            reserved: availability.reserved,
            active: false,
          });
        }
      );
      thunkAPI.dispatch(setReservationDays(days));
    }
    thunkAPI.dispatch(setReservationLoading(false));
  }
);

const reservation = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setSelectedDay: (state, action) => {
      if (state.selectedDay === action.payload) {
        state.selectedDay = null;
        for (let day in state.days) {
          state.days[day].active = true;
        }
      } else {
        state.selectedDay = action.payload;
        for (let day in state.days) {
          if (day === action.payload) {
            state.days[day].active = true;
          } else {
            state.days[day].active = false;
          }
        }
      }
    },

    setReservationLoading: (state, action) => {
      state.loading = action.payload;
    },
    setReservationDays: (state, action) => {
      state.days = action.payload;
    },
    setSelectedSlot: (state, action) => {
      let changed: boolean = false;
      for (let day in state.days) {
        // eslint-disable-next-line no-loop-func
        state.days[day].timeSlots.forEach((slot) => {
          if (
            slot.period === action.payload &&
            state.selectedDay === day &&
            slot.reserved === false
          ) {
            slot.active = true;
            state.selectedSlot = {
              day: state.selectedDay,
              period: action.payload,
            };
            changed = true;
          } else {
            slot.active = false;
          }
        });
      }
      if (!changed) {
        state.selectedSlot = null;
      }
    },
    setStage: (state, action) => {
      if (action.payload === 2 && state.selectedSlot === null) {
        state.stage = 1;
      } else {
        state.stage = action.payload;
      }
    },
    setReserved: (state, action) => {
      state.reserved = action.payload;
    },
  },
});

export const {
  setSelectedDay,
  setReservationLoading,
  setReservationDays,
  setSelectedSlot,
  setReserved,
  setStage,
} = reservation.actions;

export const selectReservationSelectedDay = (state: RootState) =>
  state.reservation.selectedDay;
export const selectReservationDays = (state: RootState) =>
  state.reservation.days;

export const selectReservationLoading = (state: RootState) =>
  state.reservation.loading;

export const selectSelectedSlot = (state: RootState) =>
  state.reservation.selectedSlot;

export const selectStage = (state: RootState) => state.reservation.stage;

export const selectReserved = (state: RootState) => state.reservation.reserved;
export default reservation.reducer;
