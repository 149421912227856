export const getAvailableTimeslots = async (salesmanId: number) => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}reservation/${salesmanId}`;
    const response = await fetch(endpoint, {
      method: "GET",
    });

    const responseData = await response.json();
    return responseData;
  } catch (e: any) {
    return { message: "Error loading available appointments." };
  }
};

export const reserveAppointment = async (
  salesmanId: number,
  dayOfWeek: string,
  period: string,
  name: string,
  email: string,
  mobile: string,
  message: string
) => {
  const requestBody = {
    salesmanId,
    dayOfWeek,
    period,
    name,
    email,
    mobile,
    message,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}reservation`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const responseData = await response.json();
    return responseData;
  } catch (e: any) {
    return { message: "An error happened while reserving slot" };
  }
};
