import React, { useEffect, useState } from "react";
import "./ReserveAppointment.css";
import { useDispatch, useSelector } from "react-redux";
import {
  TimeSlotData,
  getAvailableSlots,
  selectReservationDays,
  selectReservationLoading,
  selectReservationSelectedDay,
  selectSelectedSlot,
  selectStage,
  setSelectedDay,
  setStage,
} from "../../store/ReservationSlice";
import DayCard from "../DayCard/DayCard";
import TimeSlot from "../TimeSlot/TimeSlot";
import Loading from "../Loading/Loading";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import ReservationForm from "../ReservationForm/ReservationForm";
import { selectUser } from "../../store/UserSlice";

export default function ReserveAppointment() {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const user = useSelector(selectUser);
  const selectedSlot = useSelector(selectSelectedSlot);
  const stage = useSelector(selectStage);
  const days = useSelector(selectReservationDays);
  const dayArray = Object.entries(days).map(([dayName, { slots, active }]) => ({
    dayName,
    slots,
    active,
  }));
  const selectedDay: string | null = useSelector(selectReservationSelectedDay);
  const loading: boolean = useSelector(selectReservationLoading);
  const timeSlots: TimeSlotData[] = selectedDay
    ? days[selectedDay].timeSlots
    : [];

  const DaysOfWeek: Record<string, string> = {
    MON: "Mondays",
    TUE: "Tuesdays",
    WED: "Wednesdays",
    THU: "Thursdays",
    FRI: "Fridays",
  };

  const [salesmanId, setSalesmanId] = useState<string>();
  useEffect(() => {
    setSalesmanId(user.id);
    if (salesmanId) {
      dispatch(getAvailableSlots(Number(salesmanId)));
    }
  }, [dispatch, user, salesmanId]);

  const handleClick = () => {
    if (selectedSlot) {
      dispatch(setStage(2));
    }
  };
  return (
    <div className="reserve-appointment">
      {stage === 1 && !loading && (
        <>
          <div className="days">
            <p className="section-title">Select time</p>
            <div className="day-cards-container">
              {dayArray.map((day, index) => {
                return (
                  <DayCard
                    key={index}
                    dayName={day.dayName}
                    slots={day.slots}
                    active={day.active}
                    setDay={setSelectedDay}
                  />
                );
              })}
            </div>
          </div>
          <div className="time-slots">
            {/* changeable text */}
            {selectedDay && (
              <>
                <p className="section-title">{DaysOfWeek[selectedDay]}</p>

                {/* time slot component */}
                {timeSlots.map((timeSlot: any, index: number) => (
                  <TimeSlot
                    key={index}
                    active={timeSlot.active}
                    period={timeSlot.period}
                    dayName={selectedDay}
                    timeSlotIndex={index}
                    reserved={timeSlot.reserved}
                    reservation={true}
                  />
                ))}
                {timeSlots.length > 0 && (
                  <button onClick={handleClick}>Book TeamsLunch</button>
                )}
              </>
            )}
            {loading && stage === 1 && <Loading />}
          </div>
        </>
      )}
      {stage === 2 && <ReservationForm salesmanId={Number(salesmanId)} />}
    </div>
  );
}
