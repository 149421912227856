import React, { useState } from "react";
import "./VideoPlayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faX } from "@fortawesome/free-solid-svg-icons";
type Props = { videoUrl: string };

export default function VideoPlayer({ videoUrl }: Props) {
  // Function to extract the file extension from the video link
  const getFileExtension = (url: string) => {
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // Use non-null assertion operator (!) since we checked the length
    }
    return ""; // Return an empty string if the URL doesn't contain any periods
  };

  // Function to determine the video type based on the file extension
  const getVideoType = (extension: string) => {
    switch (extension.toLowerCase()) {
      case "mp4":
        return "video/mp4";
      case "avi":
        return "video/x-msvideo";
      case "mkv":
        return "video/x-matroska";
      case "mov":
        return "video/quicktime";
      case "wmv":
        return "video/x-ms-wmv";
      case "flv":
        return "video/x-flv";
      case "webm":
        return "video/webm";
      case "m4v":
        return "video/x-m4v";
      case "mpg":
      case "mpeg":
        return "video/mpeg";
      case "3gp":
        return "video/3gpp";
      case "3g2":
        return "video/3gpp2";
      case "mxf":
        return "application/mxf";
      case "ogv":
        return "video/ogg";
      default:
        return "";
    }
  };

  // Extract the file extension from the video link
  const fileExtension = getFileExtension(videoUrl);

  // Determine the video type
  const videoType = getVideoType(fileExtension);

  const [showVideo, setShowVideo] = useState(false);
  const handleToggleVideo = () => {
    showVideo ? setShowVideo(false) : setShowVideo(true);
  };

  return (
    <div className="video-player">
      <div className="video-icon" onClick={handleToggleVideo}>
        {!showVideo && <FontAwesomeIcon icon={faVideo} />}
        {showVideo && <FontAwesomeIcon icon={faX} />}
      </div>
      <div
        className="video-container"
        style={{
          left: showVideo ? "50%" : "-100%",
        }}
      >
        <video width="100%" height="auto" controls>
          <source src={videoUrl} type={videoType} />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}
