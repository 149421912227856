import React from "react";
import { Navigate } from "react-router-dom";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { useSelector } from "react-redux";
import { selectUsername } from "../../store/SignupSlice";
import Register from "../../pages/Register/Register";

const SignupRoute: React.FC = () => {
  const token = extractTokenFromCookie();
  let isAuthenticated = false;
  if (token && token !== "undefined") {
    isAuthenticated = true;
  }
  const username = useSelector(selectUsername);

  return !isAuthenticated && username ? <Register /> : <Navigate to="/" />;
};

export default SignupRoute;
