import React from "react";
import "./Reserve.css";
import ReserveAppointment from "../../components/ReserveAppointment/ReserveAppointment";
import { useSelector } from "react-redux";
import {
  selectReserved,
  selectSelectedSlot,
  selectStage,
} from "../../store/ReservationSlice";
import { Link } from "react-router-dom";
import { selectUser } from "../../store/UserSlice";
import WavePlayer from "../../components/WavePlayer/WavePlayer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

// import ReserveAppointment from "../../components/ReserveAppointment/ReserveAppointment";
export default function Reserve() {
  const user = useSelector(selectUser);
  const stage = useSelector(selectStage);
  const selectedSlot = useSelector(selectSelectedSlot);

  const DaysOfWeek: Record<string, string> = {
    MON: "Monday",
    TUE: "Tuesday",
    WED: "Wednesday",
    THU: "Thursday",
    FRI: "Friday",
  };
  const day = selectedSlot ? DaysOfWeek[selectedSlot.day] : null;
  const startTime = selectedSlot?.period.split("-")[0].trim();
  const reserved = useSelector(selectReserved);

  return (
    <div id="reserve">
      {reserved && (
        <>
          <div className="center-content">
            <div className="image-container">
              <img src="/images/mail-forward.png" alt="mail forwarded" />
            </div>
            <p>Request sent to {user.username}.</p>
            <div id="reserved-partnership">
              <p>In partnership with</p>
              <div className="img-container">
                <img src="images/uber-eats-logo.png" alt="Uber Eats logo" />
              </div>
            </div>
          </div>
          <div className="down-container">
            <p>
              Claim your own <Link to="/">TeamsLunch</Link>
            </p>
          </div>
        </>
      )}
      {!reserved && (
        <>
          <div
            className="left"
            id="background-image-holder"
            style={{ backgroundImage: `url(${user.profilePhoto})` }}
          >
            <div className="overlay-div">
              <p id="presentation-text-paragraph">{user.presentationText}</p>
              {user.soundUrl && <WavePlayer reservePage={true} />}
              {user.videoUrl && <VideoPlayer videoUrl={user.videoUrl} />}
            </div>
          </div>
          <div className="right">
            <div className="content">
              <div className="logo">
                <img src="/images/teamslunch-logo.png" alt="teamslunch logo" />
              </div>

              {stage === 1 && (
                <h2>
                  {/* supposed to be user name */}
                  <span>{user.username}</span>
                  <br />
                  wants to invite you to a TeamsLunch.
                </h2>
              )}
              {stage === 2 && (
                <>
                  <h2>
                    <span>{day}</span> at <span>{startTime}</span> it is.
                  </h2>
                  <p>Looking forward to meeting you.</p>
                </>
              )}
              <ReserveAppointment />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
