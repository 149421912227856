import { SignupData } from "../store/SignupSlice";

export const sendSignupRequest = async (signupData: SignupData) => {
  try {
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}auth/signup`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signupData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (e: any) {
    return { message: "An error happened while signing up." };
  }
};
