import React from "react";
import Landing from "../pages/Landing/Landing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AlertMessage from "../components/AlertMessage/AlertMessage";
import SignIn from "../pages/SIgnIn/SignIn";
import Home from "../pages/Home/Home";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import RedirectLoggedIn from "../components/RedirectLoggedIn/RedirectLoggedIn";
import SignupRoute from "../components/SignupRoute/SignupRoute";
import NotFound from "../pages/NotFound/NotFound";
import WithUsernameVerification from "../components/WithUsernameVerification/WithUsernameVerification";
import VerifyEmail from "../pages/VerifyEmail/VerifyEmail";
import PrivateRouteWithEmailVerification from "../components/PrivateRouteWithEmailVerification/PrivateRouteWithEmailVerification";
import SalesmanResponse from "../pages/SalesmanResponse/SalesmanResponse";
import Dashboard from "../pages/Dashboard/Dashboard";
import Settings from "../pages/Settings/Settings";
import Feedback from "../pages/Feedback/Feedback";
import AdminPanel from "../pages/AdminPanel/AdminPanel";
import AdminRoute from "../components/AdminRoute/AdminRoute";
import PaymentSuccess from "../pages/PaymentSuccess/PaymentSuccess";

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={<RedirectLoggedIn component={Landing} />}
            />

            <Route path="/feedback" element={<Feedback />} />
            <Route
              path="/settings"
              element={<PrivateRoute component={Settings} />}
            />
            <Route
              path="/success"
              element={<PrivateRoute component={PaymentSuccess} />}
            />
            <Route
              path="/admin"
              element={<AdminRoute component={AdminPanel} />}
            />

            <Route path="/register" element={<SignupRoute />} />
            <Route path="/reservation-action" element={<SalesmanResponse />} />
            <Route
              path="/signin"
              element={<RedirectLoggedIn component={SignIn} />}
            />
            <Route
              path="/home"
              element={<PrivateRouteWithEmailVerification component={Home} />}
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRouteWithEmailVerification component={Dashboard} />
              }
            />
            <Route
              path="/verify-email"
              element={<PrivateRoute component={VerifyEmail} />}
            />

            <Route path="/:username" element={<WithUsernameVerification />} />

            {/* Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <AlertMessage />
        </div>
      </Router>
    </>
  );
}

export default App;
