import { configureStore } from "@reduxjs/toolkit";
import signupReducer from "./SignupSlice";
import useReducer from "./UserSlice";
import alertReducer from "./AlertSlice";
import availableDatesReducer from "./AvailableDatesSlice";
import reservationReducer from "./ReservationSlice";

const store = configureStore({
  reducer: {
    signup: signupReducer,
    user: useReducer,
    alert: alertReducer,
    availableDates: availableDatesReducer,
    reservation: reservationReducer,
  },
});

// Define RootState
export type RootState = ReturnType<typeof store.getState>;

export default store;
