import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";

type GoogleSignInProps = {
  username?: string;
};
declare const google: any;

const GoogleSignIn = ({ username }: GoogleSignInProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Ensure the google.accounts.id object is available
    const initGoogleSignIn = () => {
      google.accounts.id.initialize({
        client_id: `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`,
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("google-signin-button"),
        {
          theme: "outline",
          size: "large",
          text: username ? "signup_with" : "signin_with",
          logo_alignment: "left",
          locale: "en",
          width: document.getElementById("google-signin-button")?.offsetWidth,
        } // Customize the button appearance
      );
    };

    // Load the GIS library and initialize sign-in
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initGoogleSignIn;
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCredentialResponse = (response: any) => {
    setLoading(true);
    // Send this response.credential (JWT ID token) to your backend for verification and handling
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}auth/google`, {
        token: response.credential,
        username: username ? username : null,
      })
      .then((response) => {
        if (response.data?.message) {
          dispatch(
            setAlert({
              message: response.data.message,
              severity: "error",
              isVisible: true,
            })
          );
        } else if (response.data?.access_token) {
          // Calculate the expiration date
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);

          document.cookie = `Authorization=${
            response.data.access_token
          }; expires=${expirationDate.toUTCString()}; path=/`;

          navigate("/home");
          dispatch(
            setAlert({
              message: "Login successful",
              severity: "success",
              isVisible: true,
            })
          );
        } else {
          dispatch(
            setAlert({
              message: "An error happened",
              severity: "error",
              isVisible: true,
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            message: error.message,
            severity: "error",
            isVisible: true,
          })
        );
        setLoading(false);
      });
  };

  return (
    <>
      <div
        id="google-signin-button"
        style={{
          width: "100%",
          margin: "0 auto 187px auto",
          display: loading ? "none" : "block",
        }}
      ></div>
      {loading && <Loading />}
    </>
  );
};

export default GoogleSignIn;
