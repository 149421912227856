import React, { useEffect, useState } from "react";
import "./SalesmanResponse.css"; // Make sure to import the CSS file
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import Loading from "../../components/Loading/Loading";

const SalesmanResponse = () => {
  const [loading, setLoading] = useState(true);
  // Parse query parameters from the URL
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Get the value of the 'token' parameter
  const token = params.token;

  useEffect(() => {
    const validateToken = async () => {
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}reservation/validate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: token }),
        }
      );
      const response = await jsonResponse.json();
      if (!response.valid) {
        navigate("/");
      } else {
        setLoading(false);
      }
    };
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAccept = async () => {
    const acceptMeeting = async () => {
      setLoading(true);
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}reservation/action`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ action: "accept", token: token }),
        }
      );

      const response = await jsonResponse.json();
      if (response.message) {
        dispatch(
          setAlert({
            message: response.message,
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        if (response.success) {
          dispatch(
            setAlert({
              message: "Meeting Accepted",
              severity: "success",
              isVisible: true,
            })
          );
          navigate("/");
        } else {
          dispatch(
            setAlert({
              message: "Something went wrong!",
              severity: "error",
              isVisible: true,
            })
          );
        }
      }
      setLoading(false);
    };
    acceptMeeting();
  };

  const handleDecline = async () => {
    // API call to reject the reservation
    const declineMeeting = async () => {
      setLoading(true);
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}reservation/action`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ action: "accept-with-decline", token: token }),
        }
      );

      const response = await jsonResponse.json();
      if (response.message) {
        dispatch(
          setAlert({
            message: response.message,
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        if (response.success) {
          dispatch(
            setAlert({
              message: "Meeting Declined",
              severity: "success",
              isVisible: true,
            })
          );
          navigate("/");
        } else {
          dispatch(
            setAlert({
              message: "Something went wrong!",
              severity: "error",
              isVisible: true,
            })
          );
        }
      }
      setLoading(false);
    };
    declineMeeting();
  };

  const handleAcceptWithCode = async () => {
    const acceptMeetingWithCode = async () => {
      setLoading(true);
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}reservation/action`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ action: "accept-with-code", token: token }),
        }
      );

      const response = await jsonResponse.json();
      if (response.message) {
        dispatch(
          setAlert({
            message: response.message,
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        if (response.success) {
          dispatch(
            setAlert({
              message: "Meeting Accepted",
              severity: "success",
              isVisible: true,
            })
          );
          navigate("/");
        } else {
          dispatch(
            setAlert({
              message: "Something went wrong!",
              severity: "error",
              isVisible: true,
            })
          );
        }
      }
      setLoading(false);
    };
    acceptMeetingWithCode();
  };
  return (
    <div className="button-container">
      {!loading && (
        <>
          <button className="button accept" onClick={handleAccept}>
            Accept
          </button>
          <button
            className="button accept-with-code"
            onClick={handleAcceptWithCode}
          >
            Accept and pay for lunch
          </button>
          <button className="button decline" onClick={handleDecline}>
            Decline
          </button>
        </>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default SalesmanResponse;
