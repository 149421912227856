import React, { ChangeEvent, useState } from "react";
import { setAlert } from "../../store/AlertSlice";
import { selectUser, setUser } from "../../store/UserSlice";
import axios from "axios";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

export default function VideoUploadButton() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = extractTokenFromCookie();

  const user = useSelector(selectUser);

  const handleVideoClick = () => {
    document.getElementById("videoInput")?.click();
  };
  const maxFileSizeInBytes = 30 * 1024 * 1024; // 8 mb

  const handleVideoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!token) {
      navigate("/signin");
      dispatch(
        setAlert({
          message: "Session expired",
          severity: "error",
          isVisible: true,
        })
      );
      return;
    }
    const file = e.target.files?.[0] ?? null;

    if (file) {
      setLoading(true);
      if (file.size > maxFileSizeInBytes) {
        dispatch(
          setAlert({
            message: "The video must not exceed a file size of 30 megabytes.",
            severity: "error",
            isVisible: true,
          })
        );
        setLoading(false);
        e.target.value = "";
        return;
      }

      // Validate file extension (you can customize the allowed extensions)
      const allowedExtensions = [
        "mp4",
        "avi",
        "mkv",
        "mov",
        "wmv",
        "flv",
        "webm",
        "m4v",
        "mpg",
        "mpeg",
        "3gp",
        "3g2",
        "mxf",
        "ogv",
      ];

      const fileExtension = (file.name?.split(".").pop() ?? "").toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        dispatch(
          setAlert({
            message:
              "The selected file format is not compatible. Kindly choose a video file with either .avi, .mp4, .mkv or a .mov extension.",
            severity: "error",
            isVisible: true,
          })
        );
        setLoading(false);
        e.target.value = "";

        return;
      }

      try {
        const formData = new FormData();
        formData.append("video", file);

        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
        // Replace 'your-backend-api-endpoint' with the actual URL of your backend endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}user/video`,
          formData,
          options
        );

        // Handle the response from the server as needed
        dispatch(
          setAlert({
            message: "Video uploaded.",
            severity: "success",
            isVisible: true,
          })
        );
        dispatch(setUser({ videoUrl: response.data.url }));
      } catch (error) {
        dispatch(
          setAlert({
            message: "Error uploading video.",
            severity: "error",
            isVisible: true,
          })
        );
      }
    }
    setLoading(false);
    e.target.value = "";
  };

  const handleVideoRemove = async () => {
    setLoading(true);
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}user/remove-video`,
        null,
        options
      );

      if (response.data.message) {
        dispatch(
          setAlert({
            message: "Error removing video.",
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: "Video removed.",
            severity: "success",
            isVisible: true,
          })
        );
        dispatch(setUser({ videoUrl: undefined }));
      }
    } catch (e) {
      dispatch(
        setAlert({
          message: "Error removing audio.",
          severity: "error",
          isVisible: true,
        })
      );
      console.log(e);
    }
    setLoading(false);
  };
  return (
    <>
      {!loading && !user.videoUrl && (
        <button onClick={handleVideoClick}>Video presentation</button>
      )}

      {!loading && user.videoUrl && (
        <button onClick={handleVideoRemove}>Remove video</button>
      )}
      {loading && (
        <div className="upload-loading-container">
          <Loading />
        </div>
      )}

      <input
        type="file"
        id="videoInput"
        style={{ display: "none" }}
        accept=".mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .m4v, .mpg, .mpeg, .3gp, .3g2, .mxf, .ogv"
        onChange={handleVideoUpload}
      />
    </>
  );
}
