// SettingsPage.js
import React, { useEffect, useState } from "react";
import "./Settings.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { extractPayloadFromJWT } from "../../utils/jwtPayloadParser";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import Menu from "../../components/Menu/Menu";
import Loading from "../../components/Loading/Loading";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import { getUser, selectUser } from "../../store/UserSlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

export default function Settings() {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const token = extractTokenFromCookie();
  const tokenPayload = extractPayloadFromJWT(token);

  // State to manage questions
  const [questions, setQuestions] = useState<
    { question: string; id: number }[]
  >([]);
  const [newQuestion, setNewQuestion] = useState("");

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  // const [categoryId, setCategoryId] = useState<number>(0);
  const [numCodes, setNumCodes] = useState<string>();

  const user = useSelector(selectUser);

  useEffect(() => {
    setPhone(user.phone as string);
    setName(user.name);
  }, [user.phone, user.name]);

  useEffect(() => {
    dispatch(getUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const jsonResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}user/update-info`,

      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ name, phone }),
      }
    );
    const response = await jsonResponse.json();
    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }
      dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      dispatch(
        setAlert({
          message: "Info updated!",
          severity: "success",
          isVisible: true,
        })
      );
    }
    setLoading(false);
  };

  // Function to remove a question
  const removeQuestion = async (questionId: number) => {
    try {
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}feedback/question/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ questionId: questionId }),
        }
      );
      const response = await jsonResponse.json();
      if (response.id) {
        const updatedQuestions = questions.filter((question) => {
          return question.id !== questionId;
        });
        setQuestions(updatedQuestions);
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatch(
        setAlert({
          message: "Error removing question",
          severity: "error",
          isVisible: true,
        })
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (newQuestion.trim()) {
      try {
        const jsonResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}feedback/question/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ question: newQuestion }),
          }
        );
        const response = await jsonResponse.json();

        if (response.id) {
          // change the id based on the response
          setQuestions([
            ...questions,
            { question: newQuestion, id: response.id },
          ]);
          setNewQuestion("");
        } else {
          throw new Error();
        }
      } catch (e) {
        dispatch(
          setAlert({
            message: "Error adding question",
            severity: "error",
            isVisible: true,
          })
        );
      }
    }
  };

  useEffect(() => {
    const getQuestions = async () => {
      try {
        const jsonResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}feedback/questions/${tokenPayload.id}`
        );

        const response = await jsonResponse.json();
        if (response.questions) {
          setQuestions(response.questions);
        } else {
          throw new Error();
        }
      } catch (e) {
        dispatch(
          setAlert({
            message: "Error loading data",
            severity: "error",
            isVisible: true,
          })
        );
      }
    };

    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Code categories

  // const [categories, setCategories] = useState<
  //   { id: number; name: string; price: number }[]
  // >([]);

  // useEffect(() => {
  //   const getCategories = async () => {
  //     const jsonResponse = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}codes/categories`,

  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     const response = await jsonResponse.json();
  //     setCategories(response);
  //   };
  //   getCategories();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const buyCodes = async (e: any) => {
    e.preventDefault();
    // setTimeout(() => {
    //   console.log(categoryId, numCodes);
    //   setCategoryId(0);
    //   setNumCodes(0);
    // }, 1000);

    if (numCodes) {
      const stripe = await stripePromise;
      if (!stripe) {
        console.error("Stripe failed to initialize");
        return;
      }

      // Call your backend to create the Checkout Session
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}codes/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({ categoryId: 1, quantity: Number(numCodes) }),
        }
      );
      const session = await response.json();

      if (session.message) {
        dispatch(
          setAlert({
            message: "No enough codes available.",
            severity: "error",
            isVisible: true,
          })
        );
        return;
      }
      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } else {
      dispatch(
        setAlert({
          message: "Enter number of codes to buy.",
          severity: "info",
          isVisible: true,
        })
      );
    }
  };
  return (
    <div className="settings-page">
      <header>
        <h1>Settings</h1>
      </header>
      <main>
        <section className="settings-section">
          <h2>Personal Data</h2>

          <form className="update-data-form" onSubmit={updateData}>
            <p className="field-label">Full Name</p>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <p className="field-label">Email</p>
            <input type="email" disabled value={user.email} />

            <p className="field-label">Mobile</p>
            <PhoneNumberInput value={phone} onChange={setPhone} />

            {loading ? <Loading /> : <button type="submit">Update</button>}
          </form>
        </section>

        <section className="settings-section">
          <h2>Buy Codes</h2>
          <form className="buy-code-form" onSubmit={buyCodes}>
            {/* <p className="field-label">Select Code Category</p>

            <select
              className="category-select"
              onChange={(e) => {
                setCategoryId(Number(e.target.value));
              }}
              value={categoryId as number}
            >
              <option value="0">--Select a category--</option>

              {categories.length > 0 &&
                categories.map((category) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </select> */}
            <p className="field-label">Number of Codes</p>
            <input
              type="number"
              value={numCodes}
              onChange={(e) => setNumCodes(e.target.value)}
              step="1"
              min="1"
            />
            {loading ? <Loading /> : <button type="submit">Buy</button>}
          </form>
        </section>
        <section className="settings-section">
          <h2>Feedback Questions</h2>
          <ul className="questions-list">
            {questions.map((question, index) => (
              <li className="question-item" key={index}>
                {question.question}
                <button
                  className="remove-question"
                  onClick={() => removeQuestion(question.id)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </li>
            ))}
          </ul>
          <form className="add-question-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter new question"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />

            <button type="submit">
              <FontAwesomeIcon icon={faPlus} /> Add
            </button>
          </form>
        </section>
      </main>
      <Menu />
    </div>
  );
}
