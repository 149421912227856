import React from "react";
import "./TimeSlot.css";
import { useDispatch } from "react-redux";
import { toggleTimeSlot } from "../../store/AvailableDatesSlice";
import { setSelectedSlot } from "../../store/ReservationSlice";
type Props = {
  active: boolean;
  period: string;
  dayName: string | null;
  timeSlotIndex: number;
  reservation?: boolean;
  reserved?: boolean;
};

export default function TimeSlot({
  active,
  period,
  dayName,
  timeSlotIndex,
  reservation,
  reserved,
}: Props) {
  const dispatch = useDispatch();
  const handleTimeSlotClick = () => {
    if (!reservation) {
      dispatch(
        toggleTimeSlot({ dayName: dayName, timeSlotIndex: timeSlotIndex })
      );
    } else {
      dispatch(setSelectedSlot(period));
    }
  };

  return (
    <div
      className={active ? "time-slot active" : "time-slot"}
      onClick={handleTimeSlotClick}
    >
      <p className={reserved ? "reserved" : ""}>{period}</p>
    </div>
  );
}
