import React from "react";
import "./UploadMedia.css";
import AudioUploadButton from "../AudioUploadButton/AudioUploadButton";
import VideoUploadButton from "../VideoUploadButton/VideoUploadButton";

export default function UploadMedia() {
  return (
    <>
      <div id="upload-actions">
        <p>Upload additional media</p>
        <div id="upload-buttons-container">
          <AudioUploadButton />
          <VideoUploadButton />
        </div>
      </div>
    </>
  );
}
