import React from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";

export default function PaymentSuccess() {
  const navigate = useNavigate();
  return (
    <>
      <div className="success-content">
        <p style={{ color: "#05c766" }}>Success!</p>
        <button
          onClick={(e) => {
            navigate("/dashboard");
          }}
        >
          Go to Dashboard
        </button>
      </div>
    </>
  );
}
