import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { extractPayloadFromJWT } from "../../utils/jwtPayloadParser";

interface PrivateRouteWithEmailVerificationProps
  extends Omit<RouteProps, "component"> {
  component: React.ComponentType<any>;
}

const PrivateRouteWithEmailVerification: React.FC<
  PrivateRouteWithEmailVerificationProps
> = ({ component: Component, ...rest }) => {
  const token = extractTokenFromCookie();
  let payload;

  let isAuthenticated = false;
  if (token && token !== "undefined") {
    isAuthenticated = true;
    payload = extractPayloadFromJWT(token);
  }

  if (isAuthenticated && !payload.verified) {
    return <Navigate to="/verify-email" />;
  } else if (isAuthenticated && payload.verified) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRouteWithEmailVerification;
