import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { extractPayloadFromJWT } from "../../utils/jwtPayloadParser";

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ComponentType<any>;
}

const AdminRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const token = extractTokenFromCookie();
  let isAuthorized = false;
  if (token && token !== "undefined") {
    const tokenPayload = extractPayloadFromJWT(token);
    if (tokenPayload.role === "admin") {
      isAuthorized = true;
    }
  }

  console.log(isAuthorized);
  return isAuthorized ? <Component {...rest} /> : <Navigate to="/" />;
};

export default AdminRoute;
