import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { extractTokenFromCookie } from "../../utils/extractToken";
import { RootState } from "./store";
import { extractTokenFromCookie } from "../utils/extractToken";
import axios from "axios";
import { extractPayloadFromJWT } from "../utils/jwtPayloadParser";

export type User = {
  id: string;
  username: string;
  name: string;
  email?: string;
  phone?: string;
  presentationText?: string;
  profilePhoto?: string;
  videoUrl?: string;
  soundUrl?: string;
};

export const getUser = createAsyncThunk("user/getUser", async (_, thunkAPI) => {
  const token = extractTokenFromCookie();
  if (token && token !== "undefined") {
    const tokenPayload = extractPayloadFromJWT(token);

    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}auth/${tokenPayload.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    thunkAPI.dispatch(setUser(response.data));
  }
});

const initialState: { data: User; isAuthenticated: boolean } = {
  data: {
    id: "",
    profilePhoto: "",
    name: "",
    email: "",
    phone: "",
    username: "",
    presentationText: "",
    videoUrl: "",
    soundUrl: "",
  },
  isAuthenticated: false,
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<User>>) => {
      state.data = { ...state.data, ...action.payload };
      state.isAuthenticated = true;
    },
    clearUser: (state, action: any) => {
      state = initialState;
      state.isAuthenticated = false;
    },
  },
});

// Create and export selectors
export const selectUser = (state: RootState) => {
  return state.user.data;
};

export const selectIsAuthenticated = (state: RootState) => {
  return state.user.isAuthenticated;
};

//export actions: You can update individual field using dispatch(setSignupData({field: value})
export const { setUser, clearUser } = user.actions;

// Export the favorites reducer
export default user.reducer;
