import React, { useEffect, useState } from "react";
import "./AdminPanel.css";
import Loading from "../../components/Loading/Loading";
// import Menu from "../../components/Menu/Menu";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";

export default function AdminPanel() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  // const [categoryName, setCategoryName] = useState<string>("");
  // const [categoryPrice, setCategoryPrice] = useState<number>();
  // const [categoryId, setCategoryId] = useState<number>();
  const [code, setCode] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");
  // const [categories, setCategories] = useState<
  //   { id: number; name: string; price: number }[]
  // >([]);

  // useEffect(() => {
  //   const getCategories = async () => {
  //     const jsonResponse = await fetch(
  //       `${process.env.REACT_APP_BACKEND_URL}codes/categories`,

  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     const response = await jsonResponse.json();
  //     setCategories(response);
  //   };
  //   getCategories();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const token = extractTokenFromCookie();
  // const addCategory = async (e: any) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const jsonResponse = await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}codes/category`,

  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify({ name: categoryName, price: categoryPrice }),
  //     }
  //   );
  //   const response = await jsonResponse.json();
  //   if (response.message) {
  //     let message = "";
  //     if (typeof response.message === "string") {
  //       message = response.message;
  //     } else {
  //       message = response.message[0];
  //     }
  //     dispatch(
  //       setAlert({
  //         message: message,
  //         severity: "error",
  //         isVisible: true,
  //       })
  //     );
  //   } else {
  //     setCategoryName("");
  //     setCategoryPrice(undefined);
  //     dispatch(
  //       setAlert({
  //         message: "Category added!",
  //         severity: "success",
  //         isVisible: true,
  //       })
  //     );
  //     setCategories([
  //       ...categories,
  //       { id: response.id, name: response.name, price: response.number },
  //     ]);
  //   }
  //   setLoading(false);
  // };

  const addCode = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const jsonResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}codes/code`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ code: code, categoryId: 1 }),
      }
    );
    const response = await jsonResponse.json();
    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }
      dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      // setCategoryId(0);
      setCode("");
      dispatch(
        setAlert({
          message: "Code added!",
          severity: "success",
          isVisible: true,
        })
      );
    }
    setLoading(false);
  };

  const changePrompt = async (e: any) => {
    e.preventDefault();
    const jsonResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}prompt/change`,

      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ prompt: prompt }),
      }
    );
    const response = await jsonResponse.json();
    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }
      dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      dispatch(
        setAlert({
          message: "Prompt changed!",
          severity: "success",
          isVisible: true,
        })
      );
    }
  };

  useEffect(() => {
    const getPrompt = async () => {
      const jsonResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}prompt/admin`,

        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const response = await jsonResponse.json();
      console.log(response);
      setPrompt(response.prompt);
    };
    getPrompt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-page">
      <header>
        <h1>Admin Panel</h1>
      </header>
      <main>
        {/* <section className="admin-section">
          <h2>Add Code Category</h2>

          <form className="add-category-form" onSubmit={addCategory}>
            <p className="field-label">Code Category Name</p>
            <input
              type="text"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />

            <p className="field-label">Code Category Price in USD</p>
            <input
              type="number"
              step="0.01"
              min="0"
              value={categoryPrice}
              onChange={(e) => setCategoryPrice(Number(e.target.value))}
            />

            {loading ? <Loading /> : <button type="submit">Add</button>}
          </form>
        </section> */}
        <section className="admin-section">
          <h2>Add Code</h2>

          <form className="add-code-form" onSubmit={addCode}>
            {/*  <p className="field-label">Select Code Category</p>

             <select
              className="category-select"
              onChange={(e) => {
                setCategoryId(Number(e.target.value));
              }}
              value={categoryId as number}
            >
              <option value="0">--Select a category--</option>

              {categories.length > 0 &&
                categories.map((category) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </select> */}
            <p className="field-label">Code</p>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            {loading ? <Loading /> : <button type="submit">Add</button>}
          </form>

          <h2>Gemini Prompt</h2>

          <form className="change-prompt-form" onSubmit={changePrompt}>
            <p className="field-label">Prompt</p>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            {loading ? <Loading /> : <button type="submit">Change</button>}
          </form>
        </section>
      </main>
      {/* <Menu /> */}
    </div>
  );
}
