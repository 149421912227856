import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import { setUser } from "../../store/UserSlice";
import "./SignIn.css";
import GoogleSignIn from "../../components/GoogleSignIn/GoogleSignIn";
import Loading from "../../components/Loading/Loading";
export default function SignIn() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: email,
      password: password,
    };

    const jsonResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const response = await jsonResponse.json();
    if (response.access_token) {
      // Calculate the expiration date
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);

      document.cookie = `Authorization=${
        response.access_token
      }; expires=${expirationDate.toUTCString()}; path=/`;

      navigate("/home");
      dispatch(
        setAlert({
          message: "Login successful",
          severity: "success",
          isVisible: true,
        })
      );
      dispatch(setUser(response.userData));
    } else {
      if (
        response.message ===
        "It looks like you've signed up with Google. Please use Google sign-in to log in."
      ) {
        dispatch(
          setAlert({
            message: response.message,
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: "Incorrect email or password",
            severity: "error",
            isVisible: true,
          })
        );
      }
    }
    setLoading(false);
  };
  return (
    <div id="signin">
      <div className="left">
        <img src="/images/4-landing.png" alt="background" />
        <div id="signin-partnership">
          <p>In partnership with</p>
          <div className="img-container">
            <img src="images/uber-eats-logo.png" alt="Uber Eats Logo" />
          </div>
        </div>
      </div>
      <div className="right">
        <div className="content">
          <div className="logo">
            <img src="/images/teamslunch-logo.png" alt="teamslunch logo" />
          </div>

          <h2>Whoohoo, welcome back!</h2>

          {/* <p>Your profile is almost ready to use.</p> */}
          <form className="signup-form" onSubmit={handleSubmit}>
            <p className="field-label">Email</p>
            <input type="email" onChange={(e) => setEmail(e.target.value)} />

            <p className="field-label">password</p>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            {loading ? <Loading /> : <button type="submit">Log In</button>}
          </form>

          <GoogleSignIn />
        </div>
      </div>
    </div>
  );
}
