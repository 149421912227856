import axios from "axios";
import React, { ChangeEvent, useState } from "react";
import { setAlert } from "../../store/AlertSlice";
import { useDispatch, useSelector } from "react-redux";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { selectUser, setUser } from "../../store/UserSlice";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

export default function AudioUploadButton() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const handleAudioClick = () => {
    document.getElementById("audioInput")?.click();
  };
  const maxFileSizeInBytes = 4 * 1024 * 1024;

  const handleAudioUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const token = extractTokenFromCookie();
    if (!token) {
      navigate("/signin");
      dispatch(
        setAlert({
          message: "Session expired",
          severity: "error",
          isVisible: true,
        })
      );
    }
    const file = e.target.files?.[0] ?? null;
    if (file) {
      setLoading(true);
      if (file.size > maxFileSizeInBytes) {
        dispatch(
          setAlert({
            message: "The audio must not exceed a file size of 4 megabytes.",
            severity: "error",
            isVisible: true,
          })
        );
        e.target.value = "";
        return;
      }

      // Validate file extension (you can customize the allowed extensions)
      const allowedExtensions = [
        "mp3",
        "wav",
        "aac",
        "flac",
        "ogg",
        "m4a",
        "wma",
        "alac",
        "aiff",
        "opus",
      ];

      const fileExtension = (file.name?.split(".").pop() ?? "").toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        dispatch(
          setAlert({
            message:
              "The selected file format is not compatible. Kindly choose an audio file with either .wav, .mp3, .m4a or .ogg extension.",
            severity: "error",
            isVisible: true,
          })
        );
        e.target.value = "";
        return;
      }

      try {
        const formData = new FormData();
        formData.append("audio", file);

        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
        // Replace 'your-backend-api-endpoint' with the actual URL of your backend endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}user/audio`,
          formData,
          options
        );

        // Handle the response from the server as needed
        dispatch(
          setAlert({
            message: "Audio uploaded.",
            severity: "success",
            isVisible: true,
          })
        );
        dispatch(setUser({ soundUrl: response.data.url }));
      } catch (error) {
        dispatch(
          setAlert({
            message: "Error uploading sound.",
            severity: "error",
            isVisible: true,
          })
        );
      }
      e.target.value = "";
      setLoading(false);
    }
  };

  const handleAudioRemove = async () => {
    const token = extractTokenFromCookie();
    if (!token) {
      navigate("/signin");
      dispatch(
        setAlert({
          message: "Session expired",
          severity: "error",
          isVisible: true,
        })
      );
      return;
    }
    setLoading(true);
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Replace 'your-backend-api-endpoint' with the actual URL of your backend endpoint
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}user/remove-audio`,
        null,
        options
      );

      if (response.data.message) {
        dispatch(
          setAlert({
            message: "Error removing audio.",
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: "Audio removed.",
            severity: "success",
            isVisible: true,
          })
        );
        dispatch(setUser({ soundUrl: undefined }));
      }
    } catch (e) {
      dispatch(
        setAlert({
          message: "Error removing audio.",
          severity: "error",
          isVisible: true,
        })
      );
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading && !user.soundUrl && (
        <button onClick={handleAudioClick}>Audio presentation</button>
      )}
      {!loading && user.soundUrl && (
        <button onClick={handleAudioRemove}>Remove audio</button>
      )}
      {loading && (
        <div className="upload-loading-container">
          <Loading />
        </div>
      )}
      <input
        type="file"
        id="audioInput"
        style={{ display: "none" }}
        accept=".mp3, .wav, .aac, .flac, .ogg, .m4a, .wma, .alac, .aiff, .opus"
        onChange={handleAudioUpload}
      />
    </>
  );
}
