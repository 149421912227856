import React, { useState } from "react";
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUsername } from "../../store/SignupSlice";
import { useNavigate } from "react-router-dom";
import { sendSignupRequest } from "../../services/auth.service";
import { setAlert } from "../../store/AlertSlice";
import GoogleSignIn from "../../components/GoogleSignIn/GoogleSignIn";
import Loading from "../../components/Loading/Loading";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";

export default function Register() {
  let [name, setName] = useState<string>("");
  let [email, setEmail] = useState<string>("");
  let [phone, setPhone] = useState<string>("");
  let [password, setPassword] = useState<string>("");

  let [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const username = useSelector(selectUsername);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response = await sendSignupRequest({
      username,
      name,
      email,
      phone,
      password,
    });

    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }
      dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      // navigate to signin
      navigate("/signin");
      dispatch(
        setAlert({
          message:
            "Your account has been created, and an OTP has been sent to verify your email. You may find it in your junk/spam folder.",
          severity: "success",
          isVisible: true,
        })
      );
    }
    setLoading(false);
  };

  return (
    <div id="register">
      <div className="left">
        <img src="/images/4-landing.png" alt="background" />
        <div id="register-partnership">
          <p>In partnership with</p>
          <div className="img-container">
            <img src="images/uber-eats-logo.png" alt="Uber Eats logo" />
          </div>
        </div>
      </div>
      <div className="right">
        <div className="content">
          <div className="logo">
            <img src="/images/teamslunch-logo.png" alt="teamslunch logo" />
          </div>

          <h2>
            Whoohoo, welcome <span>{username}.</span>
          </h2>

          <p>Your profile is almost ready to use.</p>
          <form className="signup-form" onSubmit={handleSubmit}>
            <p className="field-label">
              Full Name <span className="red">*</span>
            </p>
            <input type="text" onChange={(e) => setName(e.target.value)} />

            <p className="field-label">
              Email <span className="red">*</span>
            </p>
            <input type="email" onChange={(e) => setEmail(e.target.value)} />

            <p className="field-label">
              Mobile <span className="red">*</span>
            </p>
            <PhoneNumberInput value={phone} onChange={setPhone} />

            <p className="field-label">
              Password <span className="red">*</span>
            </p>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            {loading ? <Loading /> : <button type="submit">Continue</button>}
          </form>

          <GoogleSignIn username={username} />
        </div>
      </div>
    </div>
  );
}
