import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCartShopping,
  faChartLine,
  faClock,
  faClockRotateLeft,
  faDollarSign,
  faGear,
  faHandshake,
  faHome,
  faRightFromBracket,
  faStar,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { extractPayloadFromJWT } from "../../utils/jwtPayloadParser";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import { getUser, selectUser } from "../../store/UserSlice";
import Loading from "../../components/Loading/Loading";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { Link, useNavigate } from "react-router-dom";
import FeedbackModal from "../../components/FeedbackModal/FeedbackModal";
import Menu from "../../components/Menu/Menu";

export default function Dashboard() {
  //   // Function to generate random dates within a specified range
  //   const getRandomDate = (start: any, end: any) => {
  //     return new Date(
  //       start.getTime() + Math.random() * (end.getTime() - start.getTime())
  //     );
  //   };

  //   // Function to generate a random reservation state
  //   const getRandomState = () => {
  //     const states = ["accepted", "acceptedWithCode", "declined", "none"];
  //     const randomIndex = Math.floor(Math.random() * states.length);
  //     return states[randomIndex];
  //   };

  //   // Generate a random reservation
  //   const generateRandomReservation = () => {
  //     const startDate = new Date(2024, 0, 1); // Start date
  //     const endDate = new Date(2024, 2, 1); // End date

  //     // Generate random values
  //     const weekStartDate = getRandomDate(startDate, endDate);
  //     weekStartDate.setUTCHours(0, 0, 0);
  //     const startTime = getRandomDate(
  //       weekStartDate,
  //       new Date(weekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)
  //     ); // Within the week
  //     const endTime = getRandomDate(
  //       startTime,
  //       new Date(startTime.getTime() + 2 * 60 * 60 * 1000)
  //     ); // 2 hours later
  //     const name = "John Doe";
  //     const email = "johndoe@example.com";
  //     const mobile = "1234567890";
  //     const message = "This is a sample message";
  //     const clientTimezone = "UTC";
  //     const salesmanTimezone = "UTC";
  //     const state = getRandomState(); // Random reservation state
  //     const token = Math.random().toString(36).substr(2, 9); // Random alphanumeric token
  //     const meetingLink = "https://meet.example.com";

  //     // Return the reservation object
  //     return {
  //       weekStartDate,
  //       startTime,
  //       endTime,
  //       name,
  //       email,
  //       mobile,
  //       message,
  //       clientTimezone,
  //       salesmanTimezone,
  //       [state]: true,
  //       token,
  //       meetingLink,
  //     };
  //   };

  //   // Generate random reservations
  //   const generateRandomReservations = (count: number) => {
  //     const reservations = [];
  //     for (let i = 0; i < count; i++) {
  //       reservations.push(generateRandomReservation());
  //     }
  //     return reservations;
  //   };

  // Generate 100 random reservations
  //   const reservations = generateRandomReservations(100);

  const [selectedReservationId, setSelectedReservationId] = useState(null);
  const [selectReservationTranscript, setReservationTranscript] =
    useState(null);

  const getDateFromISOString = (ISOString: string) => {
    const date = new Date(ISOString);
    return date.toDateString();
  };

  const getLocaleDateFromISOString = (ISOString: string) => {
    const date = new Date(ISOString);
    return date.toLocaleDateString();
  };

  const getReservationStatus = (reservation: any) => {
    const currentDate = new Date();
    const reservationDate = new Date(reservation.startTime);
    if (reservation.accepted || reservation.acceptedWithCode) {
      if (reservationDate < currentDate) {
        return "completed";
      } else {
        return "pending";
      }
    } else if (reservation.declined) {
      return "cancelled";
    } else {
      if (reservationDate > currentDate) {
        return "pending";
      } else {
        return "cancelled";
      }
    }
  };
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const [loading, setLoading] = useState<Boolean>(false);
  const [reservations, setReservations] = useState<any>([]);
  const [totalCodes, setTotalCodes] = useState<number>(0);
  const [unusedCodes, setUnusedCodes] = useState<number>(0);
  const [totalCodesPrice, setTotalCodesPrice] = useState<number>(0);
  const [totalMeetingsCount, setTotalMeetingsCount] = useState<number>(0);
  const [totalRatings, setTotalRatings] = useState<number>(0);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = extractTokenFromCookie();
  const tokenPayload = extractPayloadFromJWT(token);

  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const jsonResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}user/dashboard-data/${tokenPayload.id}`
        );

        const response = await jsonResponse.json();
        if (response.reservations) {
          setReservations(response.reservations);
          setTotalCodes(response.totalCodesCount);
          setTotalCodesPrice(response.totalCodesCost);
          setUnusedCodes(response.unusedCodesCount);

          const totalMeetings = response.reservations.reduce(
            (accumulator: number, reservation: any) => {
              if (reservation.accepted || reservation.acceptedWithCode) {
                return accumulator + 1;
              } else {
                return accumulator;
              }
            },
            0
          );
          const totalRatings = response.reservations.reduce(
            (accumulator: number, reservation: any) => {
              return accumulator + Number(reservation.rating);
            },
            0
          );
          setTotalMeetingsCount(totalMeetings);
          setTotalRatings(totalRatings);
        } else {
          dispatch(
            setAlert({
              message: "Error loading data",
              severity: "error",
              isVisible: true,
            })
          );
        }
      } catch (e) {
        dispatch(
          setAlert({
            message: "Error loading data",
            severity: "error",
            isVisible: true,
          })
        );
      }
      setLoading(false);
    };

    setLoading(true);
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterDataForChart = (reservations: any[]): [any[], number] => {
    // Initialize an object to store counts for each state for each weekStartDate
    const countsByWeek: Record<string, Record<string, number>> = {};

    let dataMax: number = 0;
    // Iterate through reservations and count occurrences of each state for each weekStartDate
    reservations.forEach((reservation) => {
      const weekStartDate = getLocaleDateFromISOString(
        reservation.weekStartDate
      );

      // Initialize counts for the current weekStartDate if it doesn't exist yet
      if (!countsByWeek[weekStartDate]) {
        countsByWeek[weekStartDate] = {
          Accepted: 0,
          "Accepted with lunch": 0,
          Declined: 0,
          none: 0,
        };
      }

      // Increment the count for the corresponding state
      if (reservation.accepted) {
        countsByWeek[weekStartDate]["Accepted"]++;
        dataMax = Math.max(dataMax, countsByWeek[weekStartDate]["Accepted"]);
      } else if (reservation.acceptedWithCode) {
        countsByWeek[weekStartDate]["Accepted with lunch"]++;
        dataMax = Math.max(
          dataMax,
          countsByWeek[weekStartDate]["Accepted with lunch"]
        );
      } else if (reservation.declined) {
        countsByWeek[weekStartDate]["Declined"]++;
        dataMax = Math.max(dataMax, countsByWeek[weekStartDate]["Declined"]);
      } else {
        countsByWeek[weekStartDate].none++;
        dataMax = Math.max(dataMax, countsByWeek[weekStartDate].none);
      }
    });

    // Convert the countsByWeek object into an array of objects with the desired structure
    const formattedData = Object.entries(countsByWeek).map(
      ([weekStartDate, counts]) => ({
        weekStartDate,
        ...counts,
      })
    );

    return [formattedData, dataMax];
  };

  // Filter reservations data for chart plotting
  const [filteredData, dataMax] = filterDataForChart(reservations);

  // For section scrolling

  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (index: number) => {
    const section = document.getElementById(`section${index + 1}`);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
    setActiveSection(index);
  };

  // Logout

  const navigate = useNavigate();

  const handleLogoutClick = () => {
    // Clear the authorization cookie by setting its value to an empty string and its expiration date to a past date
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    navigate("/");
  };

  // legend styles
  const fontSize = window.innerWidth < 1000 ? "12px" : "16px";
  const legendStyle = { fontSize };
  return (
    <div className="dashboard">
      {loading ? (
        <Loading />
      ) : (
        <>
          <aside>
            <div className="in-page-links">
              <div
                onClick={() => scrollToSection(0)}
                className={activeSection === 0 ? "link active" : "link"}
              >
                <FontAwesomeIcon icon={faChartLine} />
                <span>Meetings Metrics</span>
              </div>
              <div
                onClick={() => scrollToSection(1)}
                className={activeSection === 1 ? "link active" : "link"}
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
                <span>Meetings History</span>
              </div>
            </div>
            <div>
              <hr />
              <div className="links">
                <Link to="/home">
                  <div className="link">
                    <FontAwesomeIcon icon={faHome} />
                    <span>Home</span>
                  </div>
                </Link>
                <Link to="/settings">
                  <div className="link">
                    <FontAwesomeIcon icon={faGear} />
                    <span>Settings</span>
                  </div>
                </Link>
                <div className="link" onClick={handleLogoutClick}>
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <span>Logout</span>
                </div>
                <div className="user-info">
                  {user.name} <br />
                  <span className="email"> {user.email}</span>
                </div>
              </div>
            </div>
          </aside>

          <div className="dashboard-main">
            <header>
              <div className="header-text">
                <p>Hello {user.name.split(" ")[0]}!</p>
                <p>Welcome back to your dashboard!</p>
              </div>
              <div className="notifications-button">
                <FontAwesomeIcon icon={faBell} />
              </div>
            </header>

            <main>
              <section className="cards-container" id="section1">
                <div className="card total-meetings">
                  <FontAwesomeIcon icon={faHandshake} />
                  <span>{totalMeetingsCount}</span>
                  <br />
                  Total Meetings
                </div>
                <div className="card total-minutes">
                  <FontAwesomeIcon icon={faClock} />
                  <span>{(totalMeetingsCount * 60).toLocaleString()}</span>
                  <br />
                  Total Minutes
                </div>
                <div className="card average-rating">
                  <FontAwesomeIcon icon={faStar} />
                  <span>
                    {isNaN(totalRatings / totalMeetingsCount)
                      ? 0.0
                      : (totalRatings / totalMeetingsCount).toFixed(1)}
                  </span>
                  <br />
                  Average Rating
                </div>
                <div className="card codes-left">
                  <FontAwesomeIcon icon={faTicket} />
                  <span>{unusedCodes}</span>
                  <br />
                  Codes Left
                </div>
                <div className="chart-container">
                  <ResponsiveContainer width="100%" aspect={2.8}>
                    <LineChart data={filteredData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#666" />
                      <XAxis
                        // minTickGap={-200}
                        axisLine={false}
                        // interval={1}
                        // textAnchor="end"
                        dataKey="weekStartDate"
                        tick={{ fontSize: 12 }}
                        // angle={-20}
                        dy={0}
                        label={{
                          value: "Week Start Date",
                          position: "insideBottomRight",
                          fontSize: Math.max(12, window.innerWidth * 0.01),
                          dy: 8,
                          dx: -11,
                        }}
                      />
                      <YAxis
                        tick={{ fontSize: 12 }}
                        domain={["0", dataMax]}
                        tickCount={dataMax - 0 + 1}
                        label={{
                          value: "Number of Meetings",
                          position: "insideTopLeft",
                          fontSize: Math.max(12, window.innerWidth * 0.01),

                          angle: -90,
                          dy:
                            window.innerWidth > 700
                              ? window.innerWidth * 0.1
                              : window.innerWidth * 0.15,
                          dx: 10,
                        }}
                      />

                      <Tooltip />

                      <Legend
                        align="right"
                        verticalAlign="top"
                        wrapperStyle={legendStyle}
                      />
                      <Line
                        type="monotone"
                        dataKey="Accepted"
                        stroke="#8884d8"
                        strokeWidth={3}
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="Accepted with lunch"
                        stroke="#82ca9d"
                        strokeWidth={3}
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="Declined"
                        stroke="#ff7300"
                        strokeWidth={3}
                        dot={false}
                      />
                      {/* <Line type="monotone" dataKey="none" stroke="#ff0000" />  */}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                <div className="card total-codes-cost">
                  <FontAwesomeIcon icon={faDollarSign} />
                  <span>{totalCodesPrice}$</span>
                  <br />
                  Codes Cost
                </div>
                <div className="card total-codes">
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span>{totalCodes}</span>
                  <br />
                  Total Codes
                </div>
              </section>

              <section className="meetings-history" id="section2">
                <h2>Meetings History</h2>
                {/* <div className="filter-bar"></div> */}
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservations.map((reservation: any) => {
                        const status = getReservationStatus(reservation);
                        return (
                          <tr
                            key={reservation.id}
                            onClick={() => {
                              if (
                                reservation.rating !== null ||
                                !reservation.pendingRecording
                              ) {
                                setSelectedReservationId(reservation.id);
                                setReservationTranscript(
                                  reservation.transcript
                                );
                              }
                            }}
                            style={{
                              cursor:
                                reservation.rating !== null ||
                                !reservation.pendingRecording
                                  ? "pointer"
                                  : "auto",
                            }}
                          >
                            <td>
                              {reservation.name} <br />
                              <span className="email">{reservation.email}</span>
                            </td>
                            <td>
                              {getDateFromISOString(reservation.startTime)}{" "}
                            </td>
                            {/* <td> {reservation.accepted? "Accepted": reservation.acceptedWithCode? "Accepted"} </td> */}
                            <td className="status-data">
                              <span
                                className={`status-indicator ${status}`}
                              ></span>
                              <span>
                                {status.charAt(0).toUpperCase() +
                                  status.slice(1)}
                              </span>
                            </td>
                            <td>
                              {reservation.rating === null ? (
                                <>Not provided</>
                              ) : (
                                <>
                                  <FontAwesomeIcon icon={faStar} />
                                  <span className="yellow">
                                    {reservation.rating}
                                  </span>
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </section>
            </main>
            {selectedReservationId && (
              <FeedbackModal
                reservationId={selectedReservationId}
                reservationTranscript={selectReservationTranscript}
                onClose={() => setSelectedReservationId(null)}
              />
            )}
          </div>
        </>
      )}
      <div id="dashboard-nav">
        <Menu />
      </div>
    </div>
  );
}
