import React from "react";
import "./DayCard.css";
import { useDispatch } from "react-redux";
import { UnknownAction } from "redux";
type Props = {
  dayName: string;
  slots: number;
  active: boolean;
  setDay: (parameter: any) => UnknownAction;
};

export default function DayCard({ dayName, slots, active, setDay }: Props) {
  const dispatch = useDispatch();
  return (
    <div
      className={active ? "day-card active" : "day-card"}
      onClick={() => dispatch(setDay(dayName))}
    >
      <p className="day-name">{dayName}</p>
      <p className={slots > 0 ? "slots-number active" : "slots-number"}>
        {slots} slots
      </p>
    </div>
  );
}
