import { DateTime } from "luxon";

/**
 * Converts a given UTC time string to its equivalent time in the provided timezone.
 *
 * @param {string} utcTimeString - The UTC time string to be converted (format: "HH:mm").
 * @param {string} timezone - The timezone in which the local time is desired.
 * @returns {string} - The equivalent time in the provided timezone formatted as "HH:mm".
 */
export function convertUTCToLocalTime(
  utcTimeString: string,
  timezone: string
): string {
  // Parse the UTC time string
  const utcDateTime = DateTime.fromFormat(utcTimeString, "HH:mm", {
    zone: "utc",
  });

  // Convert the UTC DateTime to the desired local timezone
  const localDateTime = utcDateTime.toLocal().setZone(timezone);

  // Format as "HH:mm" in the provided timezone
  const formattedLocalTime = localDateTime.toFormat("HH:mm");
  return formattedLocalTime;
}
