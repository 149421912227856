import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { setUser } from "../../store/UserSlice";
import { useDispatch } from "react-redux";
import Reserve from "../../pages/Reserve/Reserve";
import NotFound from "../../pages/NotFound/NotFound";
import Loading from "../Loading/Loading";
import "./WithUsernameVerification.css";
export default function WithUsernameVerification() {
  const dispatch = useDispatch();
  const { username } = useParams();
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/${username}`
      );
      if (response.data.id) {
        dispatch(setUser(response.data));
        setValid(true);
      } else {
        setValid(false);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="full-loading">
        <Loading />
      </div>
    );
  }

  return valid ? <Reserve /> : <NotFound />;
}
