import React, { useState } from "react";
import "./ReservationForm.css";
import { reserveAppointment } from "../../services/reservation.service";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableSlots,
  selectSelectedSlot,
  setReserved,
} from "../../store/ReservationSlice";
import { setAlert } from "../../store/AlertSlice";
import Loading from "../Loading/Loading";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
type Props = { salesmanId: number };

export default function ReservationForm({ salesmanId }: Props) {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const selectedSlot = useSelector(selectSelectedSlot);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (selectedSlot?.day && selectedSlot?.period) {
      const response = await reserveAppointment(
        salesmanId,
        selectedSlot?.day,
        selectedSlot?.period,
        name,
        email,
        mobile,
        message
      );

      if (response.id) {
        dispatch(setReserved(true));
      } else {
        let message = "";
        if (typeof response.message === "string") {
          message = response.message;
        } else {
          message = response.message[0];
        }

        dispatch(
          setAlert({
            message: message,
            severity: "error",
            isVisible: true,
          })
        );
      }
    }
    dispatch(getAvailableSlots(salesmanId));
    setLoading(false);
  };

  return (
    <div className="reservation-form">
      <form className="reservation-form" onSubmit={handleSubmit}>
        <p className="field-label">
          Full Name <span className="red">*</span>
        </p>
        <input type="text" onChange={(e) => setName(e.target.value)} />

        <p className="field-label">
          Email <span className="red">*</span>
        </p>
        <input type="email" onChange={(e) => setEmail(e.target.value)} />

        <p className="field-label">
          Mobile <span className="red">*</span>
        </p>
        {/* <input type="text" onChange={(e) => setMobile(e.target.value)} /> */}
        <PhoneNumberInput value={mobile} onChange={setMobile} />

        <p className="field-label">Message</p>
        <textarea
          rows={5}
          placeholder="Write something if you will..."
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>

        {!loading && <button type="submit">Continue</button>}
        {loading && <Loading />}
      </form>
    </div>
  );
}
