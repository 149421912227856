import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneNumberInput.css";

// Define a type for the component props
type PhoneNumberInputProps = {
  value: string | undefined;
  onChange: any;
};

const PhoneNumberInput = ({ value, onChange }: PhoneNumberInputProps) => {
  const handleOnChange = (phone: string) => {
    onChange("+" + phone);
  };
  return (
    <PhoneInput
      country={"se"}
      value={value}
      onChange={handleOnChange}
      inputProps={{ autoComplete: "tel-national" }}
    />
  );
};

export default PhoneNumberInput;
