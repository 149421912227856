import React, { ChangeEvent, ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignupData, setSignupData } from "../../store/SignupSlice";

import "./LandingCTA.css";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../store/AlertSlice";
import Loading from "../Loading/Loading";
interface LandingCTAProps {
  children: ReactNode;
  mt: boolean;
}
export default function LandingCTA({ children, mt }: LandingCTAProps) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector(selectSignupData).data.username;

  const handleUsernameSubmit = async () => {
    setLoading(true);
    if (username === "") {
      dispatch(
        setAlert({
          message: "You must type in your company name.",
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}user/validate/${username}`
      );
      const jsonResponse = await response.json();

      if (jsonResponse.valid) {
        navigate("/register");
      } else {
        dispatch(
          setAlert({
            message: "This company name is already taken.",
            severity: "error",
            isVisible: true,
          })
        );
      }
    }
    setLoading(false);
  };

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let fieldContent;
    if (e.target.value.length === 1) {
      fieldContent = ["teamslunch.com", e.target.value];
    } else {
      fieldContent = e.target.value.split("/");
    }

    if (fieldContent.length > 1) {
      dispatch(setSignupData({ username: fieldContent[1] }));
    } else {
      dispatch(setSignupData({ username: fieldContent[1] }));
    }
  };
  return (
    <div className={mt ? "cta-container mt" : "cta-container"}>
      {children}
      <div className="cta">
        <input
          type="text"
          value={username ? "teamslunch.com/" + username : ""}
          placeholder="teamslunch.com/your-company"
          onChange={handleUsernameChange}
        />
        {!loading && <button onClick={handleUsernameSubmit}>Claim</button>}
        {loading && <Loading />}
      </div>
    </div>
  );
}
