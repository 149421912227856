import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./Home.css";
import SelectDates from "../../components/SelectDates/SelectDates";
import { setAlert } from "../../store/AlertSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { extractTokenFromCookie } from "../../utils/extractToken";
import Loading from "../../components/Loading/Loading";
import { getUser, selectUser, setUser } from "../../store/UserSlice";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import { debounce } from "lodash";
import UploadMedia from "../../components/UploadMedia/UploadMedia";
import WavePlayer from "../../components/WavePlayer/WavePlayer";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu/Menu";

export default function Home() {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUser());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To wait until the user finishes typings
  const debouncedText = debounce(async (text: string) => {
    const token = extractTokenFromCookie();

    if (!token) {
      navigate("/signin");
      dispatch(
        setAlert({
          message: "Session expired",
          severity: "error",
          isVisible: true,
        })
      );
      return;
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}user/text`,
        {
          text: text,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.presentationText !== undefined) {
        dispatch(setUser({ presentationText: response.data.presentationText }));
      } else {
        dispatch(
          setAlert({
            message: "Error saving text.",
            severity: "error",
            isVisible: true,
          })
        );
      }
    } catch (e: any) {
      console.log(e);
    }
  }, 1000);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 250) {
      textareaRef.current!.value = e.target.value.slice(0, 250);
      return;
    }
    debouncedText(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      let height;
      if (window.innerWidth < 720) {
        console.log("mobile");
        height = textarea.scrollHeight;
      } else {
        height = Math.min(textarea.scrollHeight, window.innerHeight * 0.32);
      }

      textarea.style.height = `${height}px`;
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [user.presentationText]);

  const handleImageClick = () => {
    document.getElementById("imageInput")?.click();
  };
  const maxFileSizeInBytes = 4 * 1024 * 1024; // 4 MB

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const token = extractTokenFromCookie();

    if (!token) {
      navigate("/signin");
      dispatch(
        setAlert({
          message: "Session expired",
          severity: "error",
          isVisible: true,
        })
      );
      setLoading(false);
      return;
    }
    const file = e.target.files?.[0] ?? null;

    if (file) {
      setLoading(true);
      if (file.size > maxFileSizeInBytes) {
        dispatch(
          setAlert({
            message: "The image must not exceed a file size of 4 megabytes.",
            severity: "error",
            isVisible: true,
          })
        );
        e.target.value = "";
        setLoading(false);
        return;
      }

      // Validate file extension (you can customize the allowed extensions)
      const allowedExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "heif",
        "heic",
        "webp",
        "dng",
        "raw",
        "cr2",
        "nef",
        "arw",
        "orf",
        "sr2",
      ];

      const fileExtension = (file.name?.split(".").pop() ?? "").toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        dispatch(
          setAlert({
            message:
              "The selected file format is not compatible. Kindly choose a file with either a .png, .jpg or a .gif extension.",
            severity: "error",
            isVisible: true,
          })
        );
        e.target.value = "";
        setLoading(false);
        return;
      }

      try {
        const formData = new FormData();
        formData.append("image", file);

        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
        // Replace 'your-backend-api-endpoint' with the actual URL of your backend endpoint
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}user/image`,
          formData,
          options
        );

        // Handle the response from the server as needed
        dispatch(
          setAlert({
            message: "Image uploaded.",
            severity: "success",
            isVisible: true,
          })
        );
        dispatch(setUser({ profilePhoto: response.data.url }));
      } catch (error) {
        dispatch(
          setAlert({
            message: "Error uploading image.",
            severity: "error",
            isVisible: true,
          })
        );
      }
      e.target.value = "";
      setLoading(false);
    }
  };
  return (
    <div id="home">
      <div
        className="left"
        id="background-image-holder"
        style={{ backgroundImage: `url(${user.profilePhoto})` }}
      >
        {!user.profilePhoto && (
          <div className="upload-image">
            <div className="upload-image-icon-container">
              <img src="/images/upload-image.png" alt="upload icon" />
            </div>
            <input
              type="file"
              id="imageInput"
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .heif, .heic, .webp, .dng, .raw, .cr2, .nef, .arw, .orf, .sr2"
              onChange={handleImageUpload}
            />
            {loading && <Loading />}
            {!loading && (
              <button onClick={handleImageClick}>upload image</button>
            )}
          </div>
        )}

        {user.profilePhoto && (
          <div className="user-media">
            {!loading && (
              <button id="change-image" onClick={handleImageClick}>
                Change Image
              </button>
            )}
            {loading && (
              <div className="change-image-loading">
                <Loading />
              </div>
            )}
            <input
              type="file"
              id="imageInput"
              style={{ display: "none" }}
              accept=".jpg, .jpeg, .png, .gif, .bmp, .tiff, .heif, .heic, .webp, .dng, .raw, .cr2, .nef, .arw, .orf, .sr2"
              onChange={handleImageUpload}
            />
            <textarea
              ref={textareaRef}
              id="presentation-text"
              placeholder="Write something..."
              defaultValue={user.presentationText}
              onChange={handleTextChange}
            ></textarea>
            {user.soundUrl && <WavePlayer reservePage={false} />}
            <UploadMedia />
            {user.videoUrl && <VideoPlayer videoUrl={user.videoUrl} />}
          </div>
        )}
      </div>

      <div className="right">
        <div className="content">
          <div className="logo">
            <img src="/images/teamslunch-logo.png" alt="teamslunch logo" />
          </div>

          <h2>
            {/* suspected to be user name */}
            <span>{user.username}</span>
            <br />
            wants to invite you to a TeamsLunch.
          </h2>
          <SelectDates />
        </div>
      </div>
      <Menu />
    </div>
  );
}
