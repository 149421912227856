import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Day,
  TimeSlotData,
  getAvailabilities,
  selectDays,
  selectLoading,
  selectSelectedDay,
  setLoading,
  setSelectedDay,
  updateAvailability,
} from "../../store/AvailableDatesSlice";

import "./SelectDates.css";
import DayCard from "../DayCard/DayCard";
import TimeSlot from "../TimeSlot/TimeSlot";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
import Loading from "../Loading/Loading";
import { setAlert } from "../../store/AlertSlice";
import { selectUser } from "../../store/UserSlice";
import { useNavigate } from "react-router-dom";

export default function SelectDates() {
  const dayCards: Day[] = useSelector(selectDays);
  const selectedDay: string | null = useSelector(selectSelectedDay);
  const loading: boolean = useSelector(selectLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const getTimeSlotsForDay = (days: Day[], targetDayName: string | null) => {
    let targetDay;
    if (targetDayName) {
      targetDay = days.find(
        (day: any) => day.dayName === targetDayName.toUpperCase()
      );
    }
    return targetDay ? targetDay.timeSlots : [];
  };

  const [timeSlots, setTimeSlots] = useState<TimeSlotData[]>([]);

  useEffect(() => {
    setTimeSlots(getTimeSlotsForDay(dayCards, selectedDay));
  }, [selectedDay, dayCards]);

  useEffect(() => {
    dispatch(getAvailabilities());
  }, [dispatch]);

  const DaysOfWeek: Record<string, string> = {
    MON: "Mondays",
    TUE: "Tuesdays",
    WED: "Wednesdays",
    THU: "Thursdays",
    FRI: "Fridays",
  };

  const user = useSelector(selectUser);
  const linkToCopy =
    `${process.env.REACT_APP_FRONTEND_URL}${user.username}`.replaceAll(
      " ",
      "%20"
    );
  const textAreaRef = useRef(null);

  const handleCopyClick = () => {
    if (textAreaRef.current) {
      // Create a temporary textarea element to hold the text to copy
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = linkToCopy;

      // Append the textarea to the DOM
      document.body.appendChild(tempTextArea);

      // Select the text in the textarea
      tempTextArea.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(tempTextArea);

      dispatch(
        setAlert({
          message: "Link copied to clipboard.",
          severity: "success",
          isVisible: true,
        })
      );
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Book your TeamsLunch!",
          text: "Check out this link!",
          url: linkToCopy,
        });
      } else {
        throw new Error("Web Share API not supported");
      }
    } catch (error: any) {
      dispatch(
        setAlert({
          message: "Can't share the link.",
          severity: "success",
          isVisible: true,
        })
      );
    }
  };

  const handleUpdateAvailabilities = async () => {
    dispatch(setLoading(true));
    const response = await dispatch(updateAvailability()).unwrap();
    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }

      dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
      if (message === "Session expired.") {
        navigate("/signin");
      }
    } else {
      dispatch(
        setAlert({
          message: "Dates saved.",
          severity: "success",
          isVisible: true,
        })
      );
    }
    dispatch(setLoading(false));
    dispatch(setSelectedDay(null));
  };

  return (
    <div className="select-dates">
      <div className="days">
        <p className="section-title">Set availability</p>
        <div className="day-cards-container">
          {dayCards.map((day, index) => {
            return (
              <DayCard
                key={index}
                dayName={day.dayName}
                slots={day.slots}
                active={day.active}
                setDay={setSelectedDay}
              />
            );
          })}
        </div>
      </div>
      <div className="time-slots">
        {/* changeable text */}
        {selectedDay && (
          <>
            <p className="section-title">{DaysOfWeek[selectedDay]}</p>

            {/* time slot component */}
            {timeSlots.map((timeSlot: any, index: number) => (
              <TimeSlot
                key={index}
                active={timeSlot.active}
                period={timeSlot.period}
                dayName={selectedDay}
                timeSlotIndex={index}
              />
            ))}
            {loading && <Loading />}
            {!loading && (
              <button onClick={handleUpdateAvailabilities}>Save dates</button>
            )}
          </>
        )}

        {!selectedDay && (
          <>
            <div className="share-container">
              <p className="section-title">Share</p>
              <div className="share-buttons">
                <button className="share-button" onClick={handleShare}>
                  Send as
                </button>
                <button className="share-button" onClick={handleCopyClick}>
                  Copy profile link
                </button>
                {/* This textarea is needed to execute the copy command */}
                <textarea
                  ref={textAreaRef}
                  style={{
                    position: "absolute",
                    left: "-9999px",
                    display: "none",
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
