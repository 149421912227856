import React, { useEffect, useState } from "react";
import { selectUser } from "../../store/UserSlice";
import WaveSurfer from "wavesurfer.js";
import "./WavePlayer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";

type WavePlayerProps = {
  reservePage: boolean;
};
export default function WavePlayer({ reservePage }: WavePlayerProps) {
  const user = useSelector(selectUser);
  const [wavesurfer, setWavesurfer] = useState<any>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const ws = WaveSurfer.create({
      container: "#waveform",
      waveColor: "#747578",
      progressColor: "#EDEDED",
      url: user.soundUrl,
      height: 50,
    });
    setWavesurfer(ws);

    // Define the event listener function
    const handleReady = () => {
      setShow(true);
    };

    ws.on("ready", handleReady);
    ws.on("finish", handleFinish);
    // // Cleanup function to remove the 'interaction' event listener
    return () => {
      ws.un("ready", handleReady);
      ws.un("finish", handleFinish);

      ws.destroy(); // Clean up the WaveSurfer instance when the component unmounts
    };
  }, [user.soundUrl]);

  const handlePlayClick = () => {
    wavesurfer?.play();
    setIsPlaying(true);
  };

  const handlePauseClick = () => {
    wavesurfer?.pause();
    setIsPlaying(false);
  };

  const handleFinish = () => {
    setIsPlaying(false);
  };

  return (
    <div className={reservePage ? "wave-player reserve" : "wave-player home"}>
      <div id="waveform"></div>
      {!isPlaying && show && (
        <FontAwesomeIcon
          id="play-button"
          className="play-pause"
          icon={faPlay}
          size="2x"
          onClick={handlePlayClick}
        />
      )}
      {isPlaying && show && (
        <FontAwesomeIcon
          id="pause-button"
          className="play-pause"
          icon={faPause}
          size="2x"
          onClick={handlePauseClick}
        />
      )}
      {!show && <Loading />}
    </div>
  );
}
