import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { extractTokenFromCookie } from "../../utils/extractToken";

interface RedirectLoggedInProps extends Omit<RouteProps, "component"> {
  component: React.ComponentType<any>;
}

const RedirectLoggedIn: React.FC<RedirectLoggedInProps> = ({
  component: Component,
  ...rest
}) => {
  const token = extractTokenFromCookie();
  let isAuthenticated = false;
  if (token && token !== "undefined") {
    isAuthenticated = true;
  }

  return !isAuthenticated ? <Component {...rest} /> : <Navigate to="/home" />;
};

export default RedirectLoggedIn;
