import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const alert = createSlice({
  name: "alert",
  initialState: {
    message: "",
    severity: "info",
    isVisible: false,
    timestamp: Date.now(),
  },
  reducers: {
    setAlert: (state, { payload }) => {
      state.message = payload.message;
      state.severity = payload.severity;
      state.isVisible = payload.isVisible;
      state.timestamp = Date.now();
    },
  },
});

export const selectMessage = (state: RootState) => {
  return state.alert.message;
};
export const selectSeverity = (state: RootState): string => {
  return state.alert.severity;
};
export const selectIsVisible = (state: RootState) => {
  return state.alert.isVisible;
};

export const selectTimestamp = (state: RootState) => {
  return state.alert.timestamp;
};

export const { setAlert } = alert.actions;
export default alert.reducer;
