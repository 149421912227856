import React, { useCallback, useEffect } from "react";
import Alert from "@mui/material/Alert";

import "./AlertMessage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsVisible,
  selectMessage,
  selectSeverity,
  selectTimestamp,
  setAlert,
} from "../../store/AlertSlice";
const AlertMessage = () => {
  const isVisible = useSelector(selectIsVisible);
  const message = useSelector(selectMessage);
  const severity = useSelector(selectSeverity);
  const timeStamp = useSelector(selectTimestamp);
  const dispatch = useDispatch();

  // Memoize handleClose function using useCallback
  const handleClose = useCallback(() => {
    dispatch(setAlert({ message: "", severity: "info", isVisible: false }));
  }, [dispatch]);

  useEffect(() => {
    let timerId: any;
    if (isVisible) {
      timerId = setTimeout(() => {
        handleClose();
      }, 10000);
    }

    return () => {
      clearTimeout(timerId); // Clear the timer if the component unmounts
    };
  }, [isVisible, handleClose, timeStamp]);

  return (
    <>
      {isVisible && (
        <div className="alert-message">
          <Alert
            variant="filled"
            severity={severity as "error" | "warning" | "info" | "success"}
            onClose={handleClose}
          >
            {message}
          </Alert>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
