import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { AvailabilityRequest } from "../interfaces/availability.interface";
import {
  getAvailableTimeslots,
  updateAvailabilities,
} from "../services/availability.service";
import { setAlert } from "./AlertSlice";
import { convertUTCToLocalTime } from "../utils/timezones";

export interface TimeSlotData {
  period: string;
  active: boolean;
}

export interface Day {
  dayName: string;
  slots: number;
  active: boolean;
  timeSlots: TimeSlotData[];
}

interface SelectDatesState {
  loading: boolean;
  selectedDay: string | null;
  days: Day[];
}

const initialState: SelectDatesState = {
  loading: false,
  selectedDay: null,
  days: [
    {
      dayName: "MON",
      slots: 0,
      active: true,
      timeSlots: [
        { period: "11:00 - 12:00", active: false },
        { period: "12:00 - 13:00", active: false },
        { period: "13:00 - 14:00", active: false },
        { period: "14:00 - 15:00", active: false },
        { period: "15:00 - 16:00", active: false },
        { period: "16:00 - 17:00", active: false },
        { period: "17:00 - 18:00", active: false },
      ],
    },
    {
      dayName: "TUE",
      slots: 0,
      active: true,
      timeSlots: [
        { period: "11:00 - 12:00", active: false },
        { period: "12:00 - 13:00", active: false },
        { period: "13:00 - 14:00", active: false },
        { period: "14:00 - 15:00", active: false },
        { period: "15:00 - 16:00", active: false },
        { period: "16:00 - 17:00", active: false },
        { period: "17:00 - 18:00", active: false },
      ],
    },
    {
      dayName: "WED",
      slots: 0,
      active: true,
      timeSlots: [
        { period: "11:00 - 12:00", active: false },
        { period: "12:00 - 13:00", active: false },
        { period: "13:00 - 14:00", active: false },
        { period: "14:00 - 15:00", active: false },
        { period: "15:00 - 16:00", active: false },
        { period: "16:00 - 17:00", active: false },
        { period: "17:00 - 18:00", active: false },
      ],
    },
    {
      dayName: "THU",
      slots: 0,
      active: true,
      timeSlots: [
        { period: "11:00 - 12:00", active: false },
        { period: "12:00 - 13:00", active: false },
        { period: "13:00 - 14:00", active: false },
        { period: "14:00 - 15:00", active: false },
        { period: "15:00 - 16:00", active: false },
        { period: "16:00 - 17:00", active: false },
        { period: "17:00 - 18:00", active: false },
      ],
    },
    {
      dayName: "FRI",
      slots: 0,
      active: true,
      timeSlots: [
        { period: "11:00 - 12:00", active: false },
        { period: "12:00 - 13:00", active: false },
        { period: "13:00 - 14:00", active: false },
        { period: "14:00 - 15:00", active: false },
        { period: "15:00 - 16:00", active: false },
        { period: "16:00 - 17:00", active: false },
        { period: "17:00 - 18:00", active: false },
      ],
    },
  ],
};

export const updateAvailability = createAsyncThunk<{ message?: string }>(
  "availableDates/updateAvailability",
  async (_, thunkAPI) => {
    const currentState = thunkAPI.getState() as RootState;

    const days = currentState.availableDates.days;

    const requestBody: AvailabilityRequest = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      days: [],
    };

    for (let day of days) {
      let taken = false;
      for (let timeSlot of day.timeSlots) {
        if (timeSlot.active) {
          if (taken === false) {
            taken = true;
            requestBody.days.push({
              dayName: day.dayName,
              timeSlots: [{ period: timeSlot.period }],
            });
          } else {
            requestBody.days[requestBody.days.length - 1].timeSlots.push({
              period: timeSlot.period,
            });
          }
        }
      }
    }

    const response = await updateAvailabilities(requestBody);

    return response;
  }
);

export const getAvailabilities = createAsyncThunk(
  "availableDates/getAvailabilities",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));

    const response = await getAvailableTimeslots();
    if (response.message) {
      let message = "";
      if (typeof response.message === "string") {
        message = response.message;
      } else {
        message = response.message[0];
      }

      thunkAPI.dispatch(
        setAlert({
          message: message,
          severity: "error",
          isVisible: true,
        })
      );
    } else {
      thunkAPI.dispatch(setLoading(false));
      const currentState = thunkAPI.getState() as RootState;

      // Deep copy days
      const availableDates = JSON.parse(
        JSON.stringify(currentState.availableDates)
      );
      const days = availableDates.days;

      response.forEach(
        (availability: {
          id: number;
          salesmanId: number;
          dayOfWeek: string;
          startTime: string;
          endTime: string;
        }) => {
          const dayObject =
            days[
              ["MON", "TUE", "WED", "THU", "FRI"].indexOf(
                availability.dayOfWeek
              )
            ];
          const periodStart = convertUTCToLocalTime(
            availability.startTime,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );
          const periodEnd = convertUTCToLocalTime(
            availability.endTime,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          );

          const period = `${periodStart} - ${periodEnd}`;

          dayObject.timeSlots = dayObject.timeSlots.map(
            (timeSlot: { period: string; active: boolean }) => {
              if (timeSlot.period === period) {
                return { ...timeSlot, active: true };
              } else {
                return timeSlot;
              }
            }
          );
          dayObject.slots = 0;
          dayObject.timeSlots.forEach(
            (timeSlot: { period: String; active: Boolean }) => {
              if (timeSlot.active === true) {
                dayObject.slots++;
              }
            }
          );
        }
      );
      thunkAPI.dispatch(setDays(days));
    }
  }
);

const availableDates = createSlice({
  name: "availableDates",
  initialState,
  reducers: {
    setSelectedDay: (state, action) => {
      if (state.selectedDay === action.payload || action.payload === null) {
        state.selectedDay = null;
        state.days = state.days.map((day) => {
          return { ...day, active: true };
        });
      } else {
        state.selectedDay = action.payload;
        state.days = state.days.map((day) => {
          return {
            ...day,
            active: day.dayName === action.payload ? true : false,
          };
        });
      }
    },
    toggleTimeSlot: (state, action) => {
      const { dayName, timeSlotIndex } = action.payload;

      // Find the index of the day with the given dayName
      const dayIndex = state.days.findIndex(
        (day) => day.dayName === dayName.toUpperCase()
      );

      const timeSlot = state.days[dayIndex].timeSlots[timeSlotIndex];
      timeSlot.active = !timeSlot.active;

      state.days[dayIndex].slots += timeSlot.active ? 1 : -1;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDays: (state, action) => {
      state.days = action.payload;
    },
  },
});

export const { setSelectedDay, toggleTimeSlot, setLoading, setDays } =
  availableDates.actions;

export const selectSelectedDay = (state: RootState) =>
  state.availableDates.selectedDay;
export const selectDays = (state: RootState) => state.availableDates.days;

export const selectLoading = (state: RootState) => state.availableDates.loading;

export default availableDates.reducer;
