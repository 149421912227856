import { AvailabilityRequest } from "../interfaces/availability.interface";
import { extractTokenFromCookie } from "../utils/extractToken";

export const getAvailableTimeslots = async () => {
  const token = extractTokenFromCookie();
  if (token && token !== "undefined") {
    try {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}available/`;
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.json();
      return responseData;
    } catch (e: any) {
      return { message: "Error loading available dates." };
    }
  } else {
    return { message: "You are not logged in" };
  }
};

export const updateAvailabilities = async (
  requestBody: AvailabilityRequest
) => {
  const token = extractTokenFromCookie();
  if (token && token !== "undefined") {
    try {
      const endpoint = `${process.env.REACT_APP_BACKEND_URL}available/update`;
      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      return responseData;
    } catch (e: any) {
      return { message: "An error happened while saving dates." };
    }
  } else {
    return { message: "Session expired." };
  }
};
