import React, { useEffect, useState } from "react";
import "./VerifyEmail.css";
import axios from "axios";
import { extractTokenFromCookie } from "../../utils/extractToken";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail() {
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(60);
  const dispatch = useDispatch();
  const token = extractTokenFromCookie();
  const navigate = useNavigate();
  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCounter((prevCounter) => {
        // Decrease counter by 1 every second
        if (prevCounter === 0) {
          return prevCounter;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000); // 1000 milliseconds = 1 second
    return () => {
      clearInterval(counterInterval);
    };
  }, []);
  const handleContinueClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}auth/verify`,
        {
          otp: code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.message) {
        dispatch(
          setAlert({
            message: response.data.message,
            severity: "error",
            isVisible: true,
          })
        );
      } else {
        if (!response.data.access_token) {
          throw new Error("Something went wrong");
        }
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        document.cookie = `Authorization=${
          response.data.access_token
        }; expires=${expirationDate.toUTCString()}; path=/`;

        dispatch(
          setAlert({
            message: "Email verified",
            severity: "success",
            isVisible: true,
          })
        );
        navigate("/home");
      }
    } catch (e: any) {
      dispatch(
        setAlert({
          message: "Something went wrong.",
          severity: "error",
          isVisible: true,
        })
      );
    }
  };
  const handleResendClick = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}auth/resend-otp`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        dispatch(
          setAlert({
            message: "Check your email.",
            severity: "success",
            isVisible: true,
          })
        );
        setCounter(60);
      } else {
        dispatch(
          setAlert({
            message: "Something went wrong.",
            severity: "error",
            isVisible: true,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        setAlert({
          message: "Something went wrong.",
          severity: "error",
          isVisible: true,
        })
      );
    }
  };
  return (
    <div className="verify-email">
      <div className="content">
        <h1>Verify your account</h1>
        <p>We've sent a code to your email.</p>
        <p className="field-label">Code</p>
        <input type="email" onChange={(e) => setCode(e.target.value)} />
        <button className="verify-button" onClick={handleContinueClick}>
          Continue
        </button>
        <button
          className="resend-button"
          onClick={handleResendClick}
          disabled={counter !== 0}
        >
          Resend email
          {counter > 0 && <span>{counter}</span>}
        </button>
      </div>
    </div>
  );
}
