import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./FeedbackModal.css";
import { extractTokenFromCookie } from "../../utils/extractToken";

interface Answer {
  id: number;
  question: string;
  answer: string;
}

interface FeedbackData {
  rating: number;
  answers: Answer[];
}

interface FeedbackModalProps {
  reservationId: number | null;
  reservationTranscript: string | null;
  onClose: () => void;
}

const token = extractTokenFromCookie();

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  reservationId,
  reservationTranscript,
  onClose,
}) => {
  const [feedbackData, setFeedbackData] = useState<FeedbackData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}feedback/answers/${reservationId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const data = await response.json();
        if (data.message) {
          console.log(data.message);
        } else {
          setFeedbackData(data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching feedback:", error);
        setLoading(false);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        // Check for 'Escape' key
        onClose();
      }
    };

    if (reservationId !== null) {
      fetchFeedback();
    }
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [reservationId, onClose]);

  if (loading) {
    return <div className="feedback-modal loading">Loading...</div>;
  }

  if (!feedbackData) {
    return null;
  }

  return (
    <div className="feedback-modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {feedbackData.rating && (
          <>
            <h2 className="rating">
              Rating: <FontAwesomeIcon icon={faStar} />
              <span className="yellow">{feedbackData.rating}</span>
            </h2>
            <div className="feedback-list">
              {feedbackData.answers.map((answer) => (
                <div key={answer.id} className="feedback-item">
                  <h3 className="question">{answer.question}</h3>
                  <p className="answer">{answer.answer}</p>
                </div>
              ))}
            </div>
          </>
        )}

        {reservationTranscript && (
          <>
            <h2 className="rating">Transcript</h2>
            <p style={{ whiteSpace: "pre-line" }}>
              {reservationTranscript}
              {/* <pre>{reservationTranscript}</pre> */}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
