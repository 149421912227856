import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export type SignupData = {
  username: string;
  name: string;
  email: string;
  phone: string;
  password: string;
};

interface SignupState {
  data: SignupData;
}
const initialState: SignupState = {
  data: {
    username: "",
    name: "",
    email: "",
    phone: "",
    password: "",
  },
};
const signup = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setSignupData: (state, action: PayloadAction<Partial<SignupData>>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

// Create and export selectors
export const selectSignupData = (state: RootState) => {
  return state.signup;
};

export const selectUsername = (state: RootState) => {
  return state.signup.data.username;
};
//export actions: You can update individual field using dispatch(setSignupData({field: value})
export const { setSignupData } = signup.actions;

// Export the favorites reducer
export default signup.reducer;
