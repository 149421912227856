import React, { useState, useEffect } from "react";
import { Rating } from "@mui/material";
import "./Feedback.css";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/AlertSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

interface Question {
  id: number;
  question: string;
}

interface FeedbackData {
  rating: number;
  reservationToken: string;
  answers: { question: string; answer: string }[];
}

const Feedback: React.FC = () => {
  const [value, setValue] = useState<number | null>(0);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [loading, setLoading] = useState<Boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Extract reservation token from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("t");
  useEffect(() => {
    // Validate the token
    validateToken(token as string);
    // Fetch questions from backend
    fetchQuestions(token as string);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateToken = async (token: string) => {
    const jsonResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}feedback/validate/${token}`,
      {
        method: "GET",
      }
    );
    const response = await jsonResponse.json();

    if (response.message) {
      dispatch(
        setAlert({
          message: "An error occured!",
          severity: "error",
          isVisible: true,
        })
      );
    } else if (!response.valid) {
      dispatch(
        setAlert({
          message: "Feedback has already been provided!",
          severity: "info",
          isVisible: true,
        })
      );
      navigate("/");
    }
  };

  const fetchQuestions = async (reservationToken: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}feedback/questions-token/${reservationToken}`
      ); // Update with your API endpoint
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setQuestions(data.questions);
      } else {
        throw new Error();
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Error loading feedback questions! Please reload the page!",
          severity: "error",
          isVisible: true,
        })
      );
    }
  };

  const handleAnswerChange = (id: number, answer: string) => {
    setAnswers({ ...answers, [id]: answer });
  };

  const handleSubmit = async () => {
    const feedbackData: FeedbackData = {
      rating: value || 0,
      reservationToken: token as string,
      answers: questions.map((question) => ({
        question: question.question,
        answer: answers[question.id] || "",
      })),
    };

    if (feedbackData.rating === 0) {
      dispatch(
        setAlert({
          message: "Please choose a rating!",
          severity: "info",
          isVisible: true,
        })
      );
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}feedback/answers/${token}`,
        {
          // Update with your API endpoint
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(feedbackData),
        }
      );
      console.log(response);
      if (response.ok) {
        dispatch(
          setAlert({
            message: "Feedback submitted successfully!",
            severity: "success",
            isVisible: true,
          })
        );
        navigate("/");
      } else {
        dispatch(
          setAlert({
            message: "Something went wrong!",
            severity: "error",
            isVisible: true,
          })
        );
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const renderQuestions = () => {
    return questions.map((question) => (
      <div key={question.id} className="feedback-question">
        <span className="question-text">{question.question}</span>
        <textarea
          className="feedback-answer"
          maxLength={500}
          placeholder="Share your feedback here..."
          onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        ></textarea>
      </div>
    ));
  };

  return (
    <div className="feedback-container">
      {loading ? (
        <Loading />
      ) : (
        <div className="feedback-page">
          <img
            src="/images/teamslunch-logo.png"
            alt="TeamsLunch Logo"
            className="logo"
          />
          <div className="feedback-header">Feedback Form</div>

          {/* Rating Component */}
          <div className="feedback-rating">
            <Rating
              name="simple-controlled"
              value={value}
              precision={0.5}
              defaultValue={5}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <span className="rating-label">Rate your experience</span>
          </div>

          {/* Render Questions */}
          <div className="questions-container">{renderQuestions()}</div>

          {/* Submit Button */}
          <button className="submit-button" onClick={handleSubmit}>
            Submit Feedback
          </button>
        </div>
      )}
    </div>
  );
};

export default Feedback;
